import { AxiosResponse } from 'axios';
import {
  BrowserHelper,
  TExtensionCommunicationResponse,
  isExtensionCommunicationMessageStructureValid,
} from '@uniqkey-frontend/shared-app';
import Operations from '../operations';

export const dataExtractor = <T>() => (response: AxiosResponse<T>): T => response.data;

let isInProgress = false;

const setIsInProgress = (value: boolean) => {
  isInProgress = value;
};

const getIsInProgress = () => isInProgress;

const getRequestMethod = () => (
  BrowserHelper.isFirefox
    ? Operations.postMessageWithTimeoutOperation
    : Operations.sendMessageWithTimeoutOperation
);

const isResponseStructureValid = <TPayload>(
  response: unknown,
) : response is TExtensionCommunicationResponse<TPayload> => (
    isExtensionCommunicationMessageStructureValid<
      TExtensionCommunicationResponse<TPayload>
    >(response)
  );

export default {
  dataExtractor,
  setIsInProgress,
  getIsInProgress,
  getRequestMethod,
  isResponseStructureValid,
};
