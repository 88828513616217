import {
  Grid, NumericTextField, Typography, G600, INumericTextFieldProps,
} from '@uniqkey-frontend/shared-app';
import { memo } from 'react';

interface INumericTextFieldWithDefaultHelperTextProps extends INumericTextFieldProps {
  showDefaultHelperText: boolean;
  defaultHelperText: string;
}

const NumericTextFieldWithDefaultHelperText = (
  props: INumericTextFieldWithDefaultHelperTextProps,
) => {
  const {
    decimalSeparator = '',
    allowNegative = false,
    fullWidth = true,
    autoComplete = 'off',
    showDefaultHelperText,
    defaultHelperText,
    ...restProps
  } = props;

  return (
    <Grid item xs container flexDirection="column">
      <Grid item container>
        <NumericTextField
          decimalSeparator={decimalSeparator}
          allowNegative={allowNegative}
          fullWidth={fullWidth}
          autoComplete={autoComplete}
          {...restProps}
        />
      </Grid>
      {showDefaultHelperText && (
        <Grid item container ml={2}>
          <Typography variant="caption" color={G600}>
            {defaultHelperText}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default memo(NumericTextFieldWithDefaultHelperText);
