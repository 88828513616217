import { useCallback, useMemo, memo } from 'react';
import {
  ReactHookFormAutocomplete,
  Button,
  Grid,
  Popper,
  PopperActions,
  PopperContent,
  PopperTitle,
  Typography,
  useTranslations,
  useHeroSnackbar,
  usePubSub,
  buildFilterOptionsAsObject,
  ISecurityScoreOption,
  TSecurityScoreName,
  getSecurityScoreOptions,
  getPasswordStrengthOptions,
  TPasswordStrengthName,
  IPasswordStrengthOption,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import {
  ApplicationGetListResponseModel, Ownership,
} from '@uniqkey-backend-organization-web/api-client';
import { IGetVaultPasswordsParams } from '../../../../../../hooks/useVaultsAPI/interfaces';
import {
  IApplicationOption, IPassword2FAStatusOption, IReusedPasswordOption, TReusedPassword,
} from '../../../../../../helpers/filters/interfaces';
import PubSubEventEnum from '../../../../../../enums/PubSubEventEnum';
import useApplicationsAPI from '../../../../../../hooks/useApplicationsAPI';
import { getApplicationOption } from '../../../../../../helpers/filters';
import { OWNERSHIP_TRANSLATION_KEYS } from '../../../../../../constants';
import { logException } from '../../../../../../services/sentryService';
import {
  getPassword2FAStatusOptions, getReusedPasswordOptions,
} from '../../../../../../helpers/filters/options';

export interface IOrganizationLoginsTabFilterSubmitResult {
  ownership: IGetVaultPasswordsParams['ownership'];
  applicationName: IGetVaultPasswordsParams['applicationName'];
  securityScoreName: TSecurityScoreName;
  passwordStrengthName: TPasswordStrengthName;
  password2FAStatus: IGetVaultPasswordsParams['password2FAStatus'];
  reusedPassword: TReusedPassword;
}

interface IOrganizationLoginsTabFilterProps {
  isOpen: boolean;
  anchorEl: HTMLElement | null;
  onSubmit: (result: IOrganizationLoginsTabFilterSubmitResult) => void;
  onClose: () => void;
  initialValues: IOrganizationLoginsTabFilterSubmitResult;
}

type TOwnershipOption = {
  label: string;
  value: IOrganizationLoginsTabFilterSubmitResult['ownership'];
} | null;

interface IFormValues {
  ownership: TOwnershipOption;
  application: IApplicationOption | null;
  securityScore: ISecurityScoreOption | null;
  passwordStrength: IPasswordStrengthOption | null;
  password2FAStatus: IPassword2FAStatusOption | null;
  reusedPassword: IReusedPasswordOption | null;
}

const POPPER_MIN_WIDTH = { minWidth: 500 };

const OrganizationLoginsTabFilter = (props: IOrganizationLoginsTabFilterProps) => {
  const {
    isOpen, anchorEl, onSubmit, onClose, initialValues,
  } = props;
  const { t } = useTranslations();
  const { showError } = useHeroSnackbar();
  const { getApplications } = useApplicationsAPI();

  const [ownershipOptions, ownershipOptionsAsObject] = useMemo(() => {
    const options = [
      { label: t(OWNERSHIP_TRANSLATION_KEYS[Ownership.Employees]), value: Ownership.Employees },
      { label: t(OWNERSHIP_TRANSLATION_KEYS[Ownership.Groups]), value: Ownership.Groups },
      { label: t(OWNERSHIP_TRANSLATION_KEYS[Ownership.Unmanaged]), value: Ownership.Unmanaged },
    ];
    const optionsAsObject = buildFilterOptionsAsObject(options);
    return [options, optionsAsObject];
  }, [t]);

  const [securityScoreOptions, securityScoreOptionsAsObject] = useMemo(() => {
    const options = getSecurityScoreOptions(t);
    const optionsAsObject = buildFilterOptionsAsObject(options);
    return [options, optionsAsObject];
  }, [t]);

  const [password2FAStatusOptions, password2FAStatusOptionsAsObject] = useMemo(() => {
    const options = getPassword2FAStatusOptions(t);
    const optionsAsObject = buildFilterOptionsAsObject(options);
    return [options, optionsAsObject];
  }, [t]);

  const [passwordStrengthOptions, passwordStrengthOptionsAsObject] = useMemo(() => {
    const options = getPasswordStrengthOptions(t);
    const optionsAsObject = buildFilterOptionsAsObject(options);
    return [options, optionsAsObject];
  }, [t]);

  const [reusedPasswordOptions, reusedPasswordOptionsAsObject] = useMemo(() => {
    const options = getReusedPasswordOptions(t);
    const optionsAsObject = buildFilterOptionsAsObject(options);
    return [options, optionsAsObject];
  }, [t]);

  const {
    handleSubmit, control, reset, setValue, formState: { isDirty },
  } = useForm<IFormValues>({
    defaultValues: {
      ownership: ownershipOptionsAsObject[
        initialValues.ownership as keyof typeof ownershipOptionsAsObject
      ] ?? null,
      application: getApplicationOption(initialValues.applicationName),
      securityScore: securityScoreOptionsAsObject[
        initialValues.securityScoreName as keyof typeof securityScoreOptionsAsObject
      ] ?? null,
      password2FAStatus: password2FAStatusOptionsAsObject[
        initialValues.password2FAStatus as keyof typeof password2FAStatusOptionsAsObject
      ] ?? null,
      passwordStrength: passwordStrengthOptionsAsObject[
        initialValues.passwordStrengthName as keyof typeof passwordStrengthOptionsAsObject
      ] ?? null,
      reusedPassword: reusedPasswordOptionsAsObject[
        initialValues.reusedPassword as keyof typeof reusedPasswordOptionsAsObject
      ] ?? null,
    },
  });

  const handleGetApplicationsRequest = useCallback(async (searchQuery: string) => {
    try {
      const { data } = await getApplications({ page: 1, pageLength: 100, searchQuery });
      return data;
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'OrganizationLoginsTabFilter/handleGetApplicationsRequest exception',
      });
      return [];
    }
  }, [getApplications, showError, t]);

  const handleGetApplicationsResponseParser = useCallback(
    (applicationsToParse: ApplicationGetListResponseModel[]) => applicationsToParse.map(
      (application: ApplicationGetListResponseModel) => getApplicationOption(
        application.name,
      ),
    ),
    [],
  );

  const handleOnSubmit = useCallback((value: IFormValues): void => {
    const {
      ownership, application, securityScore, passwordStrength, password2FAStatus, reusedPassword,
    } = value;
    onSubmit({
      ownership: ownership?.value,
      applicationName: application?.value,
      securityScoreName: securityScore?.value,
      passwordStrengthName: passwordStrength?.value,
      password2FAStatus: password2FAStatus?.value,
      reusedPassword: reusedPassword?.value,
    });
    onClose();
    reset(value); // set default values to the selected ones to reset isDirty
  }, [reset, onSubmit, onClose]);

  const clearAll = useCallback(() => {
    setValue('ownership', null, { shouldDirty: true });
    setValue('application', null, { shouldDirty: true });
    setValue('securityScore', null, { shouldDirty: true });
    setValue('password2FAStatus', null, { shouldDirty: true });
    setValue('passwordStrength', null, { shouldDirty: true });
    setValue('reusedPassword', null, { shouldDirty: true });
  }, [setValue]);

  const handleOnFadeExited = useCallback(() => {
    reset(); // reset form values when Popper is closed and animation has finished
  }, [reset]);

  const handleReset = useCallback(() => {
    reset({
      ownership: null,
      application: null,
      securityScore: null,
      password2FAStatus: null,
      passwordStrength: null,
      reusedPassword: null,
    });
  }, [reset]);
  usePubSub(PubSubEventEnum.RESET_FILTER, handleReset);

  return (
    <Popper
      anchorEl={anchorEl}
      offsetX={-8}
      offsetY={16}
      placement="bottom-start"
      open={isOpen}
      sx={POPPER_MIN_WIDTH}
      onFadeExited={handleOnFadeExited}
    >
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <PopperTitle onClose={onClose}>
          {t('common.filterBy')}
        </PopperTitle>
        <PopperContent>
          <Grid container direction="column" rowSpacing={2}>
            <Grid item container alignItems="center">
              <Grid item xs={3}>
                <Typography>
                  {t('organizationLoginsFilter.reusedPassword.label')}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <ReactHookFormAutocomplete
                  name="reusedPassword"
                  options={reusedPasswordOptions}
                  placeholder={t('organizationLoginsFilter.reusedPassword.placeholder')}
                  control={control}
                />
              </Grid>
            </Grid>
            <Grid item container alignItems="center">
              <Grid item xs={3}>
                <Typography>
                  {t('organizationLoginsFilter.managedBy.label')}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <ReactHookFormAutocomplete
                  name="ownership"
                  options={ownershipOptions}
                  placeholder={t('organizationLoginsFilter.managedBy.placeholder')}
                  control={control}
                />
              </Grid>
            </Grid>
            <Grid item container alignItems="center">
              <Grid item xs={3}>
                <Typography>
                  {t('organizationLoginsFilter.service.label')}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <ReactHookFormAutocomplete
                  t={t}
                  name="application"
                  placeholder={t('organizationLoginsFilter.service.placeholder')}
                  dataSourceRequest={handleGetApplicationsRequest}
                  dataSourceResponseParser={handleGetApplicationsResponseParser}
                  control={control}
                />
              </Grid>
            </Grid>
            <Grid item container alignItems="center">
              <Grid item xs={3}>
                <Typography>
                  {t('organizationLoginsFilter.securityScore.label')}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <ReactHookFormAutocomplete
                  name="securityScore"
                  options={securityScoreOptions}
                  placeholder={t('organizationLoginsFilter.securityScore.placeholder')}
                  control={control}
                />
              </Grid>
            </Grid>
            <Grid item container alignItems="center">
              <Grid item xs={3}>
                <Typography>
                  {t('organizationLoginsFilter.password2FAStatus.label')}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <ReactHookFormAutocomplete
                  name="password2FAStatus"
                  options={password2FAStatusOptions}
                  placeholder={t('organizationLoginsFilter.password2FAStatus.placeholder')}
                  control={control}
                />
              </Grid>
            </Grid>
            <Grid item container alignItems="center">
              <Grid item xs={3}>
                <Typography>
                  {t('organizationLoginsFilter.passwordStrength.label')}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <ReactHookFormAutocomplete
                  name="passwordStrength"
                  options={passwordStrengthOptions}
                  placeholder={t('organizationLoginsFilter.passwordStrength.placeholder')}
                  control={control}
                />
              </Grid>
            </Grid>
          </Grid>
        </PopperContent>
        <PopperActions>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button disabled={!isDirty} fullWidth type="submit">{t('common.apply')}</Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                onClick={clearAll}
              >
                {t('common.clearAll')}
              </Button>
            </Grid>
          </Grid>
        </PopperActions>
      </form>
    </Popper>
  );
};

export default memo(OrganizationLoginsTabFilter);
