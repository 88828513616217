import { useCallback, memo, useMemo } from 'react';
import {
  ListItem,
  G100,
  BW,
  Grid,
  LoginAvatar,
  G200,
  Tooltip,
  useTranslations,
  getTimeFromNow,
  Typography,
  G600,
} from '@uniqkey-frontend/shared-app';
import { ApplicationGetInfo } from '@uniqkey-backend-organization-web/api-client';
import { useNavigate } from 'react-router-dom';
import PageRouteEnum from '../../../../../../../../enums/PageRouteEnum';

interface IListItemContentProps extends Pick<
  ApplicationGetInfo, 'iconUrl' | 'name' | 'activityAt'
> {}

interface IDashboardUnusedServicesWidgetListItemProps {
  service: ApplicationGetInfo;
  index: number;
}

const GRID_SX = { '&:hover': { backgroundColor: G200 } };
const TOOLTIP_SX = { flex: 1 };
const CONTAINER_HEIGHT = 48;

const ListItemContent = memo((props: IListItemContentProps) => {
  const {
    iconUrl, name, activityAt,
  } = props;

  const { currentLanguage } = useTranslations();

  const lastActivity = useMemo(() => getTimeFromNow({
    date: activityAt,
    locale: currentLanguage,
  }), [activityAt, currentLanguage]);

  return (
    <>
      <Grid container item xs={9.5} alignItems="center" wrap="nowrap">
        <LoginAvatar src={iconUrl} />
        <Typography variant="subtitle2" color={G600} noWrap>{name}</Typography>
      </Grid>
      <Grid item alignSelf="center">
        <Typography variant="body2">
          {lastActivity}
        </Typography>
      </Grid>
    </>
  );
});

const DashboardUnusedServicesWidgetListItem = (
  props: IDashboardUnusedServicesWidgetListItemProps,
) => {
  const { service, index } = props;
  const {
    applicationId, userCount, iconUrl, name, activityAt,
  } = service ?? {};
  const navigate = useNavigate();
  const { t } = useTranslations();

  const handleNavigate = useCallback(() => {
    navigate(`${PageRouteEnum.Services}/${applicationId}`);
  }, [navigate, applicationId]);

  return (
    <ListItem
      sx={{ backgroundColor: index % 2 === 0 ? G100 : BW }}
    >
      {userCount > 0 ? (
        <Tooltip
          title={t('dashboardUnusedServicesWidget.viewServiceTooltip')}
          boxSX={TOOLTIP_SX}
          followCursor
          cursorPointer
        >
          <Grid
            container
            wrap="nowrap"
            justifyContent="space-between"
            onClick={handleNavigate}
            paddingX={3}
            paddingY={1}
            height={CONTAINER_HEIGHT}
            overflow="hidden"
            sx={GRID_SX}
          >
            <ListItemContent iconUrl={iconUrl} name={name} activityAt={activityAt} />
          </Grid>
        </Tooltip>
      ) : (
        <Grid
          container
          wrap="nowrap"
          justifyContent="space-between"
          paddingX={3}
          paddingY={1}
          height={CONTAINER_HEIGHT}
        >
          <ListItemContent iconUrl={iconUrl} name={name} activityAt={activityAt} />
        </Grid>
      )}
    </ListItem>
  );
};

export default memo(DashboardUnusedServicesWidgetListItem);
