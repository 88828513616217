import { memo } from 'react';
import {
  Typography,
  WidgetContainer,
  SecurityScoreWidget,
  useTranslations,
  G900,
  Grid,
  Box,
  G600,
  List,
  ListItem,
} from '@uniqkey-frontend/shared-app';
import { isNumber } from 'lodash';
import { useGetVaultPasswordById } from '../../../../hooks/reactQuery';
import SecurityScoreStatisticsWidget from '../SecurityScoreStatisticsWidget';

interface IOrganizationLoginDetailsSecurityScoreWidgetProps {
  vaultId: string;
}

const LIST_SX = { listStyleType: 'disc', paddingLeft: 3 };
const LIST_ITEM_SX = { display: 'list-item' };

const OrganizationLoginDetailsSecurityScoreWidget = (
  props: IOrganizationLoginDetailsSecurityScoreWidgetProps,
) => {
  const { vaultId } = props;
  const { t } = useTranslations();
  const {
    data: vault, isLoading, isError,
  } = useGetVaultPasswordById({ vaultId });
  const {
    securityScore = 0, strength = 0, password2FaStatus, isReusedByOrganization, isReusedByUser,
  } = vault ?? {};
  const hasReusedPassword = isReusedByOrganization || isReusedByUser;

  if (isError) {
    return null;
  }

  if (!isNumber(securityScore)) {
    return (
      <WidgetContainer withShadow isLoading={isLoading}>
        <Typography variant="subtitle1" color={G900}>{t('common.securityScore')}</Typography>
        <Typography variant="caption" color={G600}>
          {t('loginDetailsSecurityScoreWidget.noSecurityScore')}
        </Typography>
      </WidgetContainer>
    );
  }

  return (
    <WidgetContainer withShadow isLoading={isLoading}>
      <Grid item container justifyContent="space-between" wrap="nowrap">
        <Grid item>
          <Typography variant="subtitle1" color={G900}>{t('common.securityScore')}</Typography>
          <Typography variant="caption" color={G600}>
            {t('loginDetailsSecurityScoreWidget.calculatedBased')}
            :
          </Typography>
          <List sx={LIST_SX}>
            <ListItem sx={LIST_ITEM_SX}>
              <Typography variant="body2" color={G600}>
                {t('loginDetailsSecurityScoreWidget.passwordStrength')}
              </Typography>
            </ListItem>
            <ListItem sx={LIST_ITEM_SX}>
              <Typography variant="body2" color={G600}>
                {t('loginDetailsSecurityScoreWidget.2FALayerOnOff')}
              </Typography>
            </ListItem>
            <ListItem sx={LIST_ITEM_SX}>
              <Typography variant="body2" color={G600}>
                {t('loginDetailsSecurityScoreWidget.ifHasReusedPassword')}
              </Typography>
            </ListItem>
          </List>
        </Grid>
        <Grid item>
          <Box pl={2} pb={2} pr={4} pt={4} display="flex" justifyContent="center">
            <SecurityScoreWidget percentage={Math.floor(securityScore)} t={t} />
          </Box>
        </Grid>
      </Grid>
      <SecurityScoreStatisticsWidget
        strength={strength ?? 0}
        password2FaStatus={password2FaStatus}
        hasReusedPassword={hasReusedPassword}
      />
    </WidgetContainer>
  );
};

export default memo(OrganizationLoginDetailsSecurityScoreWidget);
