import { memo } from 'react';
import { Ownership, RestrictionType } from '@uniqkey-backend-organization-web/api-client';
import {
  Avatar, S600, ClockIcon, GlobeIcon, LocationIcon, BW, G300, ProfileIcon, GroupsIcon,
} from '@uniqkey-frontend/shared-app';
import { TSelectionOptionType } from '../SelectionModalOption';

interface IAvatarWithIconProps {
  isEnabled?: boolean;
  avatarSize: number;
  iconSize: number;
  type: TSelectionOptionType;
}

const ICONS = {
  [RestrictionType.IpAddress]: GlobeIcon,
  [RestrictionType.Time]: ClockIcon,
  [RestrictionType.Location]: LocationIcon,
  [Ownership.Employees]: ProfileIcon,
  [Ownership.Groups]: GroupsIcon,
};

const AvatarWithIcon = (props: IAvatarWithIconProps) => {
  const {
    type, isEnabled = true, avatarSize, iconSize,
  } = props;
  const Icon = ICONS[type];
  return (
    <Avatar width={avatarSize} height={avatarSize} backgroundColor={isEnabled ? S600 : G300}>
      <Icon width={iconSize} height={iconSize} color={BW} />
    </Avatar>
  );
};

export default memo(AvatarWithIcon);
