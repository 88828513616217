import { useCallback, memo } from 'react';
import {
  Grid,
  PromptDialog,
  Typography,
  TypographyWithTooltip,
  useHeroSnackbar,
  useTranslations,
  S300,
  G200,
} from '@uniqkey-frontend/shared-app';
import { TrustedActionType } from '@uniqkey-backend-organization-web/api-client';
import { logException } from '../../../../../services/sentryService';
import { getActiveOrganizationId } from '../../../../../services/organizationService';
import { useTrustedPortalStore } from '../../../../../modules/TrustedPortalModule/store';
import useTrustedActionsAPI from '../../../../../hooks/useTrustedActionsAPI';

interface ITrustedPortalToggleProps {
  onClick: () => Promise<void>;
  isModalOpen: boolean;
  onModalClose: () => void;
  isSupporter: boolean;
}

const TrustedPortalToggle = (props: ITrustedPortalToggleProps) => {
  const {
    onClick, isModalOpen, onModalClose, isSupporter,
  } = props;
  const activeOrganizationId = getActiveOrganizationId();
  const isTrustedPortalEnabled = useTrustedPortalStore.useIsEnabledByOrganizationId()[
    activeOrganizationId!
  ] ?? false;
  const { t } = useTranslations();
  const { showSuccess, showError } = useHeroSnackbar();
  const { deleteTrustedAction } = useTrustedActionsAPI();

  const handleDeactivate = useCallback(async () => {
    try {
      await deleteTrustedAction(TrustedActionType.TrustedPortal);
      onModalClose();
      showSuccess({ text: t('trustedPortalToggle.trustPortalDeactivated') });
    } catch (e) {
      logException(e, { message: 'TrustedPortalToggle/handleDeactivate exception' });
      showError({
        text: t('common.somethingWentWrong'),
      });
    }
  }, [deleteTrustedAction, showSuccess, showError, t, onModalClose]);

  const handleToggle = useCallback(() => {
    if (isSupporter) { // supporter is not allowed to turn TP off
      return;
    }
    onClick();
  }, [isSupporter, onClick]);

  let title;
  if (isTrustedPortalEnabled) {
    title = isSupporter
      ? t('trustedPortalToggle.supporterCannotDeactivate')
      : t('trustedPortalToggle.clickToDeactivate');
  } else {
    title = t('trustedPortalToggle.clickToActivate');
  }

  return (
    <>
      <TypographyWithTooltip
        variant="subtitle1"
        title={title}
        backgroundColor={isTrustedPortalEnabled ? S300 : G200}
        borderRadius={2}
        padding="0px 4px"
        cursor={isSupporter ? 'not-allowed' : 'pointer'}
        onClick={handleToggle}
      >
        {isTrustedPortalEnabled ? t('trustedPortalToggle.on') : t('trustedPortalToggle.off')}
      </TypographyWithTooltip>
      {isModalOpen && (
        <PromptDialog
          open={isModalOpen}
          onClose={onModalClose}
          onSubmit={handleDeactivate}
          title={t('deactivateModal.title')}
          approvalButtonText={t('deactivateModal.deactivate')}
          rejectionButtonText={t('common.cancel')}
          approvalButtonColor="primary"
        >
          <Grid container rowGap={2}>
            <Grid item>
              <Typography>
                {t('deactivateModal.description.paragraph1')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                {t('deactivateModal.description.paragraph2')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                {t('deactivateModal.description.paragraph3')}
              </Typography>
            </Grid>
          </Grid>
        </PromptDialog>
      )}
    </>
  );
};

export default memo(TrustedPortalToggle);
