import {
  WidgetContainer,
  Grid,
  Typography,
  Divider,
  G900,
  G600,
  useTranslations,
  Button,
  INumericTextFieldProps,
  noLeadingZeroValidator,
  minMaxValueValidator,
  useHeroSnackbar,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import { useCallback, memo } from 'react';
import { ISoftDeletionPeriodWidgetProps } from '../SoftDeletionPeriodWidget';
import NumericTextFieldWithDefaultHelperText from '../NumericTextFieldWithDefaultHelperText';
import { FIVE_YEARS } from '../../../../../../constants';
import { logException } from '../../../../../../services/sentryService';
import { useUpdateSoftDeletionRetentionPeriod } from '../../../../../../hooks/reactQuery';

interface IUpdateSoftDeletionRetentionPeriodFormValue {
  softDeletionPeriodInDaysForArchivedEmployees: string;
  softDeletionPeriodInDaysForUnmanagedVaults: string;
  softDeletionPeriodInDaysForAuditLogs: string;
}

interface ISoftDeletionPeriodWidgetContainerProps extends Pick<
  ISoftDeletionPeriodWidgetProps, 'data'
> {}

const SoftDeletionPeriodWidgetContainer = (props: ISoftDeletionPeriodWidgetContainerProps) => {
  const {
    data,
  } = props;
  const { t } = useTranslations();
  const { showError, showSuccess } = useHeroSnackbar();

  const {
    softDeletionPeriodInDaysForArchivedEmployees,
    softDeletionPeriodInDaysForUnmanagedVaults,
    softDeletionPeriodInDaysForAuditLogs,
  } = data;

  const {
    mutate: updateSoftDeletionRetentionPeriod,
    isLoading: isSoftDeletionRetentionPeriodUpdating,
  } = useUpdateSoftDeletionRetentionPeriod({ useOptimisticUpdates: true });

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<IUpdateSoftDeletionRetentionPeriodFormValue>({
    mode: 'all',
    defaultValues: {
      softDeletionPeriodInDaysForArchivedEmployees:
        softDeletionPeriodInDaysForArchivedEmployees?.toString() ?? '',
      softDeletionPeriodInDaysForUnmanagedVaults:
        softDeletionPeriodInDaysForUnmanagedVaults?.toString() ?? '',
      softDeletionPeriodInDaysForAuditLogs: softDeletionPeriodInDaysForAuditLogs?.toString() ?? '',
    },
  });

  const [
    watchSoftDeletionPeriodInDaysForArchivedEmployees,
    watchSoftDeletionPeriodInDaysForUnmanagedVaults,
    watchSoftDeletionPeriodInDaysForAuditLogs,
  ] = watch([
    'softDeletionPeriodInDaysForArchivedEmployees',
    'softDeletionPeriodInDaysForUnmanagedVaults',
    'softDeletionPeriodInDaysForAuditLogs',
  ]);

  const handleSoftDeletionPeriodInDaysForArchivedEmployeesChange = useCallback<
    NonNullable<INumericTextFieldProps['onChange']>
  >((event) => {
    setValue(
      'softDeletionPeriodInDaysForArchivedEmployees',
      event.target.value,
      { shouldDirty: true },
    );
  }, [setValue]);

  const handleSoftDeletionPeriodInDaysForUnmanagedVaultsChange = useCallback<
    NonNullable<INumericTextFieldProps['onChange']>
  >((event) => {
    setValue(
      'softDeletionPeriodInDaysForUnmanagedVaults',
      event.target.value,
      { shouldDirty: true },
    );
  }, [setValue]);

  const handleSoftDeletionPeriodInDaysForAuditLogsChange = useCallback<
    NonNullable<INumericTextFieldProps['onChange']>
  >((event) => {
    setValue(
      'softDeletionPeriodInDaysForAuditLogs',
      event.target.value,
      { shouldDirty: true },
    );
  }, [setValue]);

  const handleUpdateSoftDeletionRetentionPeriod = useCallback((
    formValue: IUpdateSoftDeletionRetentionPeriodFormValue,
  ): void => {
    const {
      softDeletionPeriodInDaysForArchivedEmployees: preSoftDeletionPeriodInDaysForArchivedEmployees,
      softDeletionPeriodInDaysForUnmanagedVaults: preSoftDeletionPeriodInDaysForUnmanagedVaults,
      softDeletionPeriodInDaysForAuditLogs: preSoftDeletionPeriodInDaysForAuditLogs,
    } = formValue;

    const parsedSoftDeletionPeriodInDaysForArchivedEmployees = parseInt(
      preSoftDeletionPeriodInDaysForArchivedEmployees,
      10,
    );
    const parsedSoftDeletionPeriodInDaysForUnmanagedVaults = parseInt(
      preSoftDeletionPeriodInDaysForUnmanagedVaults,
      10,
    );
    const parsedSoftDeletionPeriodInDaysForAuditLogs = parseInt(
      preSoftDeletionPeriodInDaysForAuditLogs,
      10,
    );
    updateSoftDeletionRetentionPeriod(
      {
        softDeletionPeriodInDaysForArchivedEmployees: Number.isNaN(
          parsedSoftDeletionPeriodInDaysForArchivedEmployees,
        ) ? null : parsedSoftDeletionPeriodInDaysForArchivedEmployees,
        softDeletionPeriodInDaysForUnmanagedVaults: Number.isNaN(
          parsedSoftDeletionPeriodInDaysForUnmanagedVaults,
        ) ? null : parsedSoftDeletionPeriodInDaysForUnmanagedVaults,
        softDeletionPeriodInDaysForAuditLogs: Number.isNaN(
          parsedSoftDeletionPeriodInDaysForAuditLogs,
        ) ? null : parsedSoftDeletionPeriodInDaysForAuditLogs,
      },
      {
        onError: (e) => {
          showError({ text: t('common.somethingWentWrong') });
          logException(e, {
            // eslint-disable-next-line max-len
            message: 'SoftDeletionPeriodWidgetContainer/handleUpdateSoftDeletionRetentionPeriod exception',
          });
        },
        onSuccess: () => {
          showSuccess({
            text: t('settingsPage.retentionPeriodTab.softDeletionPeriodWidget.success.message'),
          });
          reset({
            // eslint-disable-next-line max-len
            softDeletionPeriodInDaysForArchivedEmployees: preSoftDeletionPeriodInDaysForArchivedEmployees,
            // eslint-disable-next-line max-len
            softDeletionPeriodInDaysForUnmanagedVaults: preSoftDeletionPeriodInDaysForUnmanagedVaults,
            softDeletionPeriodInDaysForAuditLogs: preSoftDeletionPeriodInDaysForAuditLogs,
          });
        },
      },
    );
  }, [reset, showError, showSuccess, t, updateSoftDeletionRetentionPeriod]);

  const disabled = !isDirty || !!errors.softDeletionPeriodInDaysForUnmanagedVaults
    || !!errors.softDeletionPeriodInDaysForArchivedEmployees
    || !!errors.softDeletionPeriodInDaysForAuditLogs;

  return (
    <WidgetContainer container withShadow p={3}>
      <Grid container flexDirection="column">
        <form onSubmit={handleSubmit(handleUpdateSoftDeletionRetentionPeriod)} autoComplete="off">
          <Grid item mb={0.5}>
            <Typography variant="body3" color={G900}>
              {t('settingsPage.retentionPeriodTab.softDeletionPeriodWidget.title')}
            </Typography>
          </Grid>
          <Divider />
          <Grid item mt={0.5}>
            <Typography variant="body2" color={G600}>
              {t('settingsPage.retentionPeriodTab.softDeletionPeriodWidget.description')}
            </Typography>
          </Grid>
          <Grid item container flexDirection="column" mt={0.5} rowGap={2}>
            <Grid container gap={1}>
              {/* Same padding as on TextField to center text */}
              <Grid item pt="12px" width={160}>
                <Typography>
                  {/* eslint-disable-next-line max-len */}
                  {t('settingsPage.retentionPeriodTab.softDeletionPeriodWidget.unmanagedVaults.title')}
                </Typography>
              </Grid>
              <NumericTextFieldWithDefaultHelperText
                allowLeadingZeros
                name="softDeletionPeriodInDaysForUnmanagedVaults"
                error={!!errors.softDeletionPeriodInDaysForUnmanagedVaults}
                helperText={errors.softDeletionPeriodInDaysForUnmanagedVaults?.message}
                // eslint-disable-next-line max-len
                placeholder={t('settingsPage.retentionPeriodTab.textField.placeholder')}
                value={watchSoftDeletionPeriodInDaysForUnmanagedVaults}
                onChange={handleSoftDeletionPeriodInDaysForUnmanagedVaultsChange}
                inputProps={{
                  ...register('softDeletionPeriodInDaysForUnmanagedVaults', {
                    validate: {
                      noLeadingZero: (value) => noLeadingZeroValidator(value)
                        || t('validation.noLeadingZero'),
                      minMaxValue: (value) => minMaxValueValidator({
                        value,
                        minValue: 1,
                        maxValue: FIVE_YEARS,
                      }) || t('validation.atLeast30Days'),
                    },
                  }),
                }}
                showDefaultHelperText={!errors.softDeletionPeriodInDaysForUnmanagedVaults}
                defaultHelperText={t(
                  'settingsPage.retentionPeriodTab.textField.info',
                )}
              />
            </Grid>
            <Grid container gap={1}>
              {/* Same padding as on TextField to center text */}
              <Grid item pt={1.5} width={160}>
                <Typography>
                  {/* eslint-disable-next-line max-len */}
                  {t('settingsPage.retentionPeriodTab.softDeletionPeriodWidget.archivedUsers.title')}
                </Typography>
              </Grid>
              <NumericTextFieldWithDefaultHelperText
                allowLeadingZeros
                name="softDeletionPeriodInDaysForArchivedEmployees"
                error={!!errors.softDeletionPeriodInDaysForArchivedEmployees}
                helperText={errors.softDeletionPeriodInDaysForArchivedEmployees?.message}
                // eslint-disable-next-line max-len
                placeholder={t('settingsPage.retentionPeriodTab.textField.placeholder')}
                value={watchSoftDeletionPeriodInDaysForArchivedEmployees}
                onChange={handleSoftDeletionPeriodInDaysForArchivedEmployeesChange}
                inputProps={{
                  ...register('softDeletionPeriodInDaysForArchivedEmployees', {
                    validate: {
                      noLeadingZero: (value) => noLeadingZeroValidator(value)
                        || t('validation.noLeadingZero'),
                      minMaxValue: (value) => minMaxValueValidator({
                        value,
                        minValue: 1,
                        maxValue: FIVE_YEARS,
                      }) || t('validation.atLeast30Days'),
                    },
                  }),
                }}
                showDefaultHelperText={!errors.softDeletionPeriodInDaysForArchivedEmployees}
                defaultHelperText={t(
                  'settingsPage.retentionPeriodTab.textField.info',
                )}
              />
            </Grid>
            <Grid container gap={1}>
              {/* Same padding as on TextField to center text */}
              <Grid item pt="12px" width={160}>
                <Typography>
                  {t('settingsPage.retentionPeriodTab.softDeletionPeriodWidget.auditLogs.title')}
                </Typography>
              </Grid>
              <NumericTextFieldWithDefaultHelperText
                allowLeadingZeros
                name="softDeletionPeriodInDaysForAuditLogs"
                error={!!errors.softDeletionPeriodInDaysForAuditLogs}
                helperText={errors.softDeletionPeriodInDaysForAuditLogs?.message}
                // eslint-disable-next-line max-len
                placeholder={t('settingsPage.retentionPeriodTab.textField.placeholder')}
                value={watchSoftDeletionPeriodInDaysForAuditLogs}
                onChange={handleSoftDeletionPeriodInDaysForAuditLogsChange}
                inputProps={{
                  ...register('softDeletionPeriodInDaysForAuditLogs', {
                    validate: {
                      noLeadingZero: (value) => noLeadingZeroValidator(value)
                        || t('validation.noLeadingZero'),
                      minMaxValue: (value) => minMaxValueValidator({
                        value,
                        minValue: 180,
                        maxValue: FIVE_YEARS,
                      }) || t('validation.atLeast180Days'),
                    },
                  }),
                }}
                showDefaultHelperText={!errors.softDeletionPeriodInDaysForAuditLogs}
                defaultHelperText={t(
                  'settingsPage.retentionPeriodTab.softDeletionPeriodWidget.auditLogs.info',
                )}
              />
            </Grid>
          </Grid>
          <Grid container item mt={3} justifyContent="flex-end">
            <Button
              variant="outlined"
              type="submit"
              isLoading={isSoftDeletionRetentionPeriodUpdating}
              disabled={disabled}
            >
              {isDirty ? t('common.apply') : t('common.change')}
            </Button>
          </Grid>
        </form>
      </Grid>
    </WidgetContainer>
  );
};

export default memo(SoftDeletionPeriodWidgetContainer);
