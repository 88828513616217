/* eslint-disable max-len */

import { BrowserNameEnum } from '@uniqkey-frontend/shared-app';

const isProduction = () => process.env.REACT_APP_ENVIRONMENT === 'production';

const CHROME = {
  STORE_URL: 'https://chromewebstore.google.com/detail/uniqkey-20/pfblclopmfndegcljgcjbhkpclhepeod',
  EXTENSION_ID: isProduction() ? 'pfblclopmfndegcljgcjbhkpclhepeod' : 'acjbhoidlomhkifkmgdhmalpfdpfafem',
};

const storeURLs = {
  [BrowserNameEnum.Chrome]: CHROME.STORE_URL,
  [BrowserNameEnum.Safari]: 'https://apps.apple.com/ua/app/uniqkey-2-0-extension/id1668092452',
  [BrowserNameEnum.MicrosoftEdge]: 'https://microsoftedge.microsoft.com/addons/detail/uniqkey-20/cghalgafnnidajakoacnnekgkaifionm',
  [BrowserNameEnum.Firefox]: 'https://addons.mozilla.org/en-US/firefox/addon/uniqkey-2-0/',
  [BrowserNameEnum.Opera]: CHROME.STORE_URL,
};

const extensionIDs = {
  /*
    All except Firefox use this for "runtime.sendMessage";
    in Firefox's case - used for "window.postMessage".
  */
  [BrowserNameEnum.Chrome]: CHROME.EXTENSION_ID,
  [BrowserNameEnum.Safari]: isProduction() ? 'uniqkey.browserextension.extension (LE394U28V8)' : 'eu.uniqkey.Uniqkey-2-0.Extension (UNSIGNED)',
  [BrowserNameEnum.MicrosoftEdge]: 'cghalgafnnidajakoacnnekgkaifionm',
  [BrowserNameEnum.Firefox]: '{9529fdd7-7244-4229-9ca1-8ec06092f08d}',
  [BrowserNameEnum.Opera]: CHROME.EXTENSION_ID, // extension is not published into Opera, use chrome id instead
};

const config = {
  requestTimeout: parseInt(process.env.REACT_APP_REQUEST_TIMEOUT as string, 10) ?? 10000,
  environment: process.env.REACT_APP_ENVIRONMENT,
  getExtensionApiUrl: () => `https://organization-api-extension.${process.env.REACT_APP_ENVIRONMENT}.next.uniqkey.eu`,
  isProduction,
  installUniqkeyUrl: 'https://www.uniqkey.eu/install',
  storeURLs,
  extensionIDs,
};

export default config;
