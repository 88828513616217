import {
  Either,
  OmitFromUnion,
  TExtensionCommunicationRequest,
  TExtensionCommunicationResponse,
  TimeEnum,
} from '@uniqkey-frontend/shared-app';
import {
  OnboardingSendMessageMalformedResponseError,
  OnboardingSendMessageNotAvailableError,
  OnboardingSendMessageSendError,
  OnboardingSendMessageTimeoutError,
  TOnboardingSendMessageWithTimeoutOperationErrors,
} from './errors';
import Helpers from '../../helpers';

// @ts-ignore
const sendMessage = window.chrome?.runtime?.sendMessage || window.browser?.runtime?.sendMessage;

export interface ISendMessageWithTimeoutOperationParams<TPayload> {
  extensionId: string;
  message: OmitFromUnion<TExtensionCommunicationRequest<TPayload>, 'messageId'>;
  timeout?: TimeEnum;
}

const sendMessageWithTimeoutOperation = <
  TRequestPayload, TResponsePayload
>(params: ISendMessageWithTimeoutOperationParams<TRequestPayload>): Promise<
  Either<
    TExtensionCommunicationResponse<TResponsePayload>,
    TOnboardingSendMessageWithTimeoutOperationErrors
  >
> => new Promise((resolve) => {
    if (!sendMessage) {
      resolve(new OnboardingSendMessageNotAvailableError());
      return;
    }

    const { extensionId, message, timeout = TimeEnum.ThreeSeconds } = params;

    const timeoutId = setTimeout(() => {
      resolve(new OnboardingSendMessageTimeoutError());
    }, timeout);

    (async () => {
      try {
        const response = await sendMessage(extensionId, message);
        clearTimeout(timeoutId);
        if (!Helpers.isResponseStructureValid<TResponsePayload>(response)) {
          resolve(new OnboardingSendMessageMalformedResponseError());
          return;
        }
        resolve(response);
      } catch (error) {
        clearTimeout(timeoutId);
        resolve(new OnboardingSendMessageSendError());
      }
    })();
  });

export default sendMessageWithTimeoutOperation;
