import {
  BaseEitherError,
  Either,
  ExtensionCommunicationMessageTypeEnum,
  TimeEnum,
  createDeferred,
} from '@uniqkey-frontend/shared-app';
import {
  OnboardingWaitUntilInstalledUnhandledError,
  TOnboardingWaitUntilInstalledOperationErrors,
} from './errors';
import Helpers from '../../helpers';

interface IWaitUntilInstalledOperationParams {
  extensionId: string;
}

type TReturn = Either<true, TOnboardingWaitUntilInstalledOperationErrors>;

const REQUEST_TIMEOUT = TimeEnum.OneSecond;
const INTERVAL_TIMEOUT = TimeEnum.TwoSeconds;

const waitUntilInstalledOperation = async (
  params: IWaitUntilInstalledOperationParams,
): Promise<TReturn> => {
  const { extensionId } = params;
  const deferred = createDeferred<TReturn>();
  const method = Helpers.getRequestMethod();

  const intervalId = setInterval(async () => {
    try {
      const result = await method<void, void>({
        extensionId,
        message: { messageType: ExtensionCommunicationMessageTypeEnum.PING },
        timeout: REQUEST_TIMEOUT,
      });
      const isError = result instanceof BaseEitherError;
      if (!isError && result.messageType === ExtensionCommunicationMessageTypeEnum.PONG) {
        clearInterval(intervalId);
        deferred.resolve(true);
      }
    } catch (error) {
      clearInterval(intervalId);
      deferred.resolve(new OnboardingWaitUntilInstalledUnhandledError());
    }
  }, INTERVAL_TIMEOUT);

  return deferred.promise;
};

export default waitUntilInstalledOperation;
