import { memo, useCallback, useState } from 'react';
import {
  G500,
  G600,
  Typography,
  useTranslations,
  LinkedServices as MUILinkedServices,
  ExpandableSection,
  Grid, Collapse,
} from '@uniqkey-frontend/shared-app';

import { useGetServicesByApplicationId } from '../../hooks/reactQuery';

interface ILinkedServicesProps {
  applicationId: string | null;
  open?: boolean;
  onToggle?: () => void
}

const LinkedServices = (props: ILinkedServicesProps) => {
  const { applicationId, open, onToggle } = props;

  const [localIsOpen, localSetIsOpen] = useState(open ?? false);

  const { t } = useTranslations();

  const handleLocalToggle = useCallback(() => {
    localSetIsOpen((localOpen) => !localOpen);
  }, []);

  const { data } = useGetServicesByApplicationId(
    { applicationId: applicationId! },
    {
      enabled: !!applicationId,
    },
  );

  const {
    domainRules: preDomainRules,
    desktopApplications: preDesktopApplications,
  } = data ?? {};

  const domainRules = preDomainRules || [];
  const desktopApplications = preDesktopApplications || [];

  const domainRulesLen = domainRules.length ?? 0;
  const desktopApplicationsLen = desktopApplications.length ?? 0;

  const servicesTotalCount = domainRulesLen + desktopApplicationsLen;

  // if only 1 service - do not show
  const isIn = servicesTotalCount > 1;

  return (
    <Collapse in={isIn} unmountOnExit>
      <Grid container pt={2}>
        <ExpandableSection
          expanded={open ?? localIsOpen}
          onChange={onToggle ?? handleLocalToggle}
          PrimaryTypographyComponent={(
            <Typography variant="caption" color={G600}>
              {t('linkedServices.title')}
            </Typography>
          )}
          SecondaryTypographyComponent={(
            <Typography variant="body1" color={G500}>
              {t('linkedServices.subtitle', { totalCount: servicesTotalCount })}
            </Typography>
          )}
        >
          <MUILinkedServices
            desktopApplications={desktopApplications}
            domainRules={domainRules}
            desktopApplicationsTitle={t('linkedServices.desktopApplications.title')}
            domainRulesTitle={t('linkedServices.domainRules.title')}
          />
        </ExpandableSection>
      </Grid>
    </Collapse>
  );
};

export default memo(LinkedServices);
