import {
  useState, useCallback, useMemo, SyntheticEvent, useEffect,
} from 'react';
import {
  Typography,
  useTranslations,
  Grid,
  S600,
  Button,
  useHeroSnackbar,
  PanelContent,
  CircularProgress,
  G600,
  buildAndDownloadCSV,
  TFunction,
} from '@uniqkey-frontend/shared-app';
import {
  useLocation, Link, useNavigate,
} from 'react-router-dom';
import {
  GetOrganizationsResponseModel, Ownership, CheckStatusResponseModel,
} from '@uniqkey-backend-organization-web/api-client';
import MigrationPanel from '../../components/MigrationPanel';
import MigrationImage from '../../images/MigrationImage';
import MigrationPanelHeader from '../../components/MigrationPanel/components/MigrationPanelHeader';
import MigrationPanelContent
  from '../../components/MigrationPanel/components/MigrationPanelContent';
import InstallMobileAppModal from './components/InstallMobileAppModal';
import MainPageRouteEnum from '../../../enums/PageRouteEnum';
import MigrationPageRouteEnum from '../../enums/MigrationPageRouteEnum';
import { useGetOrganizations, useCheckStatus } from '../../hooks/reactQuery/useMigrationAPI';
import { getMasterPasswordHash, logout } from '../../services/authService';
import NotMigratedDataModal from './components/NotMigratedDataModal';
import VaultTypeNameEnum from '../../../enums/VaultTypeNameEnum';

interface IExportData {
  VaultType: string;
  Name: CheckStatusResponseModel['name'];
  ManagedBy: CheckStatusResponseModel['ownershipEntityName'];
  DataOwner: string;
}

const CSV_NAME = 'not_migrated_data';
const CSV_CONFIG = { columns: ['VaultType', 'Name', 'ManagedBy', 'DataOwner'] };

interface ICreateVaultExportDataParams {
  vaultType: VaultTypeNameEnum;
  vault: CheckStatusResponseModel;
  organizationName: string;
  t: TFunction;
}

const createVaultExportData = (params: ICreateVaultExportDataParams) => {
  const {
    vaultType, vault, organizationName, t,
  } = params;
  return {
    VaultType: t(`migration.notMigratedDataModal.vaults.${vaultType}`),
    Name: vault.name,
    ManagedBy: vault.ownership === Ownership.Unmanaged
      ? t('ownership.unmanaged')
      : vault.ownershipEntityName,
    DataOwner: vault.isPrivate ? t('migration.notMigratedDataModal.private') : organizationName,
  };
};

const LINK_STYLE = { color: S600 };

const CongratsPage = () => {
  const [isInstallMobileModalOpen, setIsInstallMobileModalOpen] = useState<boolean>(false);
  const [notMigratedDataModalOpen, setNotMigratedDataModalOpen] = useState(false);
  const [exportCSVLoading, setExportCSVLoading] = useState(false);
  const handleNotMigratedDataModalOpen = useCallback(() => setNotMigratedDataModalOpen(true), []);
  const handleNotMigratedDataModalClose = useCallback(() => {
    setExportCSVLoading(false);
    setNotMigratedDataModalOpen(false);
  }, []);
  const { state: locationsState } = useLocation();
  const { name, id } = locationsState as GetOrganizationsResponseModel;
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { showError, showSuccess } = useHeroSnackbar();
  const masterPasswordHash = getMasterPasswordHash();
  const { data: organizationsData, isLoading: areOrganizationsLoading } = useGetOrganizations(
    { masterPasswordHash: masterPasswordHash! },
    {
      enabled: !!masterPasswordHash,
      onError: () => {
        showError({ text: t('common.somethingWentWrong') });
      },
    },
  );
  const { organizations = [] } = organizationsData ?? {};
  const notMigratedOrganizationsCount = useMemo(() => organizations.filter(
    (org) => !org.isAlreadyMigrated,
  ).length, [organizations]);
  const {
    data: checkStatusData,
    isLoading: isCheckStatusLoading,
  } = useCheckStatus({ currentGenOrganizationId: id }, {
    onError: () => {
      showError({ text: t('common.somethingWentWrong') });
    },
  });
  const {
    notMigratedLogins,
    notMigratedCreditCards,
    notMigratedSecureNotes,
  } = checkStatusData ?? {};

  const toggleInstallMobileAppModal = useCallback(
    () => setIsInstallMobileModalOpen((isOpen) => !isOpen),
    [],
  );
  const handleLinkClick = useCallback((event: SyntheticEvent) => {
    event.preventDefault();
    toggleInstallMobileAppModal();
  }, [toggleInstallMobileAppModal]);

  const handleClose = useCallback(() => {
    navigate(MainPageRouteEnum.Login);
    logout();
    window.location.reload();
  }, [navigate]);

  const runNewMigrationClick = useCallback(() => {
    navigate(MigrationPageRouteEnum.Organizations);
  }, [navigate]);

  const handleExportToCSVClick = useCallback(() => {
    setExportCSVLoading(true);
    const exportData: IExportData[] = [];
    if (notMigratedLogins?.length) {
      notMigratedLogins.forEach((vault) => exportData.push(createVaultExportData({
        vaultType: VaultTypeNameEnum.Password, vault, organizationName: name, t,
      })));
    }
    if (notMigratedCreditCards?.length) {
      notMigratedCreditCards.forEach((vault) => exportData.push(createVaultExportData({
        vaultType: VaultTypeNameEnum.CreditCard, vault, organizationName: name, t,
      })));
    }
    if (notMigratedSecureNotes?.length) {
      notMigratedSecureNotes.forEach((vault) => exportData.push(createVaultExportData({
        vaultType: VaultTypeNameEnum.SecureNote, vault, organizationName: name, t,
      })));
    }
    if (!exportData.length) {
      setExportCSVLoading(false);
      showError({ text: t('common.somethingWentWrong') });
      return;
    }
    buildAndDownloadCSV({
      exportData, name: CSV_NAME, config: CSV_CONFIG,
    });
    showSuccess({ text: t('migration.notMigratedDataModal.successMessage') });
    handleNotMigratedDataModalClose();
  }, [
    handleNotMigratedDataModalClose,
    name,
    notMigratedCreditCards,
    notMigratedLogins,
    notMigratedSecureNotes,
    showError,
    showSuccess,
    t,
  ]);

  const isLoading = areOrganizationsLoading || isCheckStatusLoading;

  useEffect(() => {
    if (notMigratedLogins?.length
      || notMigratedCreditCards?.length
      || notMigratedSecureNotes?.length) {
      handleNotMigratedDataModalOpen();
    }
  }, [
    handleNotMigratedDataModalOpen,
    notMigratedCreditCards?.length,
    notMigratedLogins?.length,
    notMigratedSecureNotes?.length,
  ]);

  if (isLoading) {
    return (
      <PanelContent p={3}>
        <Grid container alignItems="center" justifyContent="center" className="min-height-100-vh">
          <CircularProgress />
        </Grid>
      </PanelContent>
    );
  }

  return (
    <MigrationPanel>
      <MigrationPanelHeader
        title={t('migration.congratsPage.header')}
      />
      <MigrationPanelContent>
        <MigrationImage />
        <Grid item container justifyContent="center" textAlign="center" rowSpacing={2} mt={3}>
          <Grid item xs={9}>
            <Typography variant="body1">
              {t('migration.congratsPage.migrationCompleted', { name })}
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="subtitle1" fontWeight={700}>
              {t('migration.congratsPage.dontHaveUniqkey')}
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="body1" component="span">
              {t('migration.congratsPage.click')}
            </Typography>
            <span>&nbsp;</span>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link to="#" style={LINK_STYLE} onClick={handleLinkClick}>
              <Typography component="span">
                {t('migration.congratsPage.installUniqkey')}
              </Typography>
            </Link>
          </Grid>
        </Grid>
        <Grid item container justifyContent="center" textAlign="center" rowSpacing={2} mt={3}>
          <Grid item xs={3.5}>
            <Button fullWidth variant="outlined" onClick={handleClose}>
              {t('migration.congratsPage.close')}
            </Button>
          </Grid>
          {!!notMigratedOrganizationsCount && (
            <>
              <Grid item xs={12}>
                <Typography variant="body1" color={G600}>
                  {t('migration.congratsPage.thereHaveLeft', {
                    count: notMigratedOrganizationsCount,
                  })}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button variant="text" onClick={runNewMigrationClick}>
                  {t('migration.congratsPage.runNewMigration')}
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </MigrationPanelContent>
      <InstallMobileAppModal
        handleToggle={toggleInstallMobileAppModal}
        isModalOpen={isInstallMobileModalOpen}
      />
      {notMigratedDataModalOpen && (
        <NotMigratedDataModal
          notMigratedLogins={notMigratedLogins}
          notMigratedCreditCards={notMigratedCreditCards}
          notMigratedSecureNotes={notMigratedSecureNotes}
          organizationName={name}
          isOpen={notMigratedDataModalOpen}
          onClose={handleNotMigratedDataModalClose}
          onExport={handleExportToCSVClick}
          isLoading={exportCSVLoading}
        />
      )}
    </MigrationPanel>
  );
};

export default CongratsPage;
