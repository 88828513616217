import {
  ElevationEnum,
  G100,
  G500,
  HeroGrid,
  HeroIconWithPolygonContainer,
  HeroPanel,
  HeroTypography,
  G900,
  SizeEnum,
  TypographyVariantEnum,
  TypographyWeightEnum,
  useTranslations,
} from '@uniqkey-frontend/shared-app';

const CommunicationMessage = () => {
  const { t } = useTranslations();
  return (
    <HeroPanel backgroundColor={G100} elevation={ElevationEnum.NONE}>
      <HeroGrid
        container
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        p={SizeEnum.Size16}
      >
        <HeroGrid mb={SizeEnum.Size24}>
          <HeroIconWithPolygonContainer isLoading />
        </HeroGrid>
        <HeroGrid mb={SizeEnum.Size4}>
          <HeroTypography
            variant={TypographyVariantEnum.XL}
            fontWeight={TypographyWeightEnum.Medium}
            color={G900}
          >
            {t('onboarding.processing.header')}
          </HeroTypography>
        </HeroGrid>
        <HeroGrid>
          <HeroTypography variant={TypographyVariantEnum.MD} color={G500}>
            {t('onboarding.processing.description')}
          </HeroTypography>
        </HeroGrid>
      </HeroGrid>
    </HeroPanel>
  );
};

export default CommunicationMessage;
