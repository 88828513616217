import {
  BaseEitherError,
  BrowserHelper,
  BrowserNameEnum,
  Either,
  ExtensionCommunicationMessageTypeEnum,
  openURLInNewTab,
} from '@uniqkey-frontend/shared-app';
import config from '../../config';
import { ISendMessageWithTimeoutOperationParams } from '../sendMessageWithTimeoutOperation';
import { IPostMessageWithTimeoutOperationParams } from '../postMessageWithTimeoutOperation';
import {
  OnboardingInitialExtensionCheckError,
  OnboardingInitialExtensionCheckUnsupportedBrowserError,
  TOnboardingInitialExtensionCheckOperationErrors,
} from './errors';
import Helpers from '../../helpers';

const { browserName, isEdge, isBrowserSupported } = BrowserHelper;

const VENDOR_EXTENSION_ID = config.extensionIDs[browserName as BrowserNameEnum];
const EDGE_FALLBACK_EXTENSION_ID = config.extensionIDs[BrowserNameEnum.Chrome];
const VENDOR_STORE_URL = config.storeURLs[browserName as BrowserNameEnum];

type TParams =
  | ISendMessageWithTimeoutOperationParams<void>
  | IPostMessageWithTimeoutOperationParams<void>;

interface IInitialExtensionCheckOperationReturn {
  isInstalled: boolean;
  extensionId: string;
}

const initialExtensionCheckOperation = async (): Promise<
  Either<
    IInitialExtensionCheckOperationReturn,
    TOnboardingInitialExtensionCheckOperationErrors
  >
> => {
  try {
    if (!isBrowserSupported()) {
      openURLInNewTab({ url: config.installUniqkeyUrl, target: 'InstallUniqkeyPage' });
      return new OnboardingInitialExtensionCheckUnsupportedBrowserError();
    }
    const method = Helpers.getRequestMethod();
    const params: TParams = {
      extensionId: VENDOR_EXTENSION_ID,
      message: { messageType: ExtensionCommunicationMessageTypeEnum.PING },
    };

    const requests = [
      method<void, void>(params),
    ];

    if (isEdge) { // Edge can also have an extension from the Chrome store
      requests.push(method<void, void>({
        ...params,
        extensionId: EDGE_FALLBACK_EXTENSION_ID,
      }));
    }

    const [vendorAttemptResult, fallbackAttemptResult] = await Promise.all(requests);

    const isVendorAttemptError = vendorAttemptResult instanceof BaseEitherError;
    const isFallbackAttemptError = fallbackAttemptResult instanceof BaseEitherError;

    if (isVendorAttemptError) {
      if (isEdge && !isFallbackAttemptError) {
        /*
          Handle case when the user has installed an extension
          from the Chrome store in the Edge browser
        */
        return { isInstalled: true, extensionId: EDGE_FALLBACK_EXTENSION_ID };
      }
      openURLInNewTab({ url: VENDOR_STORE_URL, target: 'StorePage' });
      return { isInstalled: false, extensionId: VENDOR_EXTENSION_ID };
    }

    return { isInstalled: true, extensionId: VENDOR_EXTENSION_ID };
  } catch (error) {
    return new OnboardingInitialExtensionCheckError();
  }
};

export default initialExtensionCheckOperation;
