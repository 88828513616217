import { useCallback } from 'react';
import {
  Grid,
  TypographyWithHelp,
  WidgetContainer,
  G900,
  useTranslations,
  useHeroSnackbar,
  Typography,
  G500,
  List,
} from '@uniqkey-frontend/shared-app';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { GroupOrderPropertyV2 } from '@uniqkey-backend-organization-web/api-client';
import { lowerFirst } from 'lodash';
import PageRouteEnum from '../../../../../../enums/PageRouteEnum';
import DashboardGroupSecurityScoreWidgetListItem from
  '../DashboardGroupSecurityScoreWidgetListItem';
import DashboardNoDataWidget from '../../../DashboardNoDataWidget';
import { useLowSecurityScoreGroups } from '../../../../../../hooks/reactQuery/useEmployeeGroupsAPI';

const DashboardGroupSecurityScoreWidget = () => {
  const { t } = useTranslations();
  const { showError } = useHeroSnackbar();
  const navigate = useNavigate();

  const { data, isLoading, isError } = useLowSecurityScoreGroups({
    onError: () => {
      showError({ text: t('common.somethingWentWrong') });
    },
  });
  const { groups } = data ?? {};

  const handleNavigation = useCallback(() => {
    const search = createSearchParams({
      columnOrderBy: lowerFirst(GroupOrderPropertyV2.GroupSecurityScore),
      columnOrderDirection: 'asc',
    }).toString();
    navigate({
      pathname: PageRouteEnum.Groups,
      search,
    });
  }, [navigate]);

  if (isError) {
    return null;
  }

  if (isLoading) {
    return (
      <WidgetContainer container withShadow isLoading={isLoading} minHeight={270} />
    );
  }

  if (!groups?.length) {
    return (
      <DashboardNoDataWidget
        tooltipTranslationKey="dashboardGroupSecurityScoreWidget.tooltip"
        titleTranslationKey="dashboardGroupSecurityScoreWidget.title"
        noDataTranslationKey="dashboardGroupSecurityScoreWidget.noData"
      />
    );
  }

  return (
    <WidgetContainer container withShadow p={0}>
      <Grid container py={2} rowGap={1}>
        <Grid item xs={12} px={3}>
          <Grid item>
            <TypographyWithHelp
              helperText={t('dashboardGroupSecurityScoreWidget.tooltip')}
              color={G900}
            >
              {t('dashboardGroupSecurityScoreWidget.title')}
            </TypographyWithHelp>
          </Grid>
          <Grid item container justifyContent="space-between">
            <Grid item>
              <Typography variant="caption" color={G500}>
                {t('dashboardGroupSecurityScoreWidget.groups')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption" color={G500}>
                {t('dashboardGroupSecurityScoreWidget.secureScore')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <List disablePadding>
            {groups.map((group, index) => (
              <DashboardGroupSecurityScoreWidgetListItem
                group={group}
                index={index}
                key={group.groupId}
              />
            ))}
          </List>
        </Grid>
        <Grid item my={2} container justifyContent="center">
          <Typography variant="subtitle1" asLink onClick={handleNavigation}>
            {t('dashboardGroupSecurityScoreWidget.button')}
          </Typography>
        </Grid>
      </Grid>
    </WidgetContainer>
  );
};

export default DashboardGroupSecurityScoreWidget;
