import { useMemo, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BreadcrumbsContainer,
  Breadcrumbs,
  useHeroSnackbar,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import PageRouteEnum from '../../../../enums/PageRouteEnum';
import { useGetGroupById } from '../../../../hooks/reactQuery';

interface IGroupPageBreadcrumbsProps {
  groupId: string;
}

const GroupPageBreadcrumbs = (props: IGroupPageBreadcrumbsProps) => {
  const { groupId } = props;
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { showError } = useHeroSnackbar();

  const { data: group, isLoading } = useGetGroupById(
    { groupId },
    {
      onError: () => {
        showError({ text: t('common.somethingWentWrong') });
        navigate(PageRouteEnum.Groups);
      },
    },
  );

  const breadcrumbsNodes = useMemo(() => {
    if (isLoading || !group) {
      return [];
    }
    return [
      { title: t('navigation.groups'), onClick: () => navigate(PageRouteEnum.Groups) },
      { title: group.name },
    ];
  }, [isLoading, group, navigate, t]);

  return (
    <BreadcrumbsContainer>
      <Breadcrumbs
        nodes={breadcrumbsNodes}
        isLoading={isLoading}
      />
    </BreadcrumbsContainer>
  );
};

export default memo(GroupPageBreadcrumbs);
