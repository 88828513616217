import {
  useCallback, useState, memo, useMemo,
} from 'react';
import {
  ActionButton,
  Button,
  Divider,
  encryptSymmetric,
  Grid,
  MoveIcon,
  PanelContent,
  PlusIcon,
  SearchableTextField,
  Tooltip,
  FilterButton,
  usePopper,
  useHeroSnackbar,
  useTranslations,
  ShareIcon,
  ErrorIcon,
  useMapKeyValueExtractor,
  PromptModal,
  Typography,
  ISearchableTextFieldProps,
  IFilterButtonProps,
  MoveToEmployeeIcon,
  MoveToGroupIcon,
  SeverityEnum,
} from '@uniqkey-frontend/shared-app';
import { EmployeeAccountStatus, Ownership } from '@uniqkey-backend-organization-web/api-client';
import useEmployeeGroupLoginsTable, {
  IEmployeeGroupLoginsTableRow,
} from '../../../../hooks/tables/useEmployeeGroupLoginsTable';
import EmployeeLoginsTable from '../../../../components/tables/EmployeeLoginsTable';
import CreateLoginModal, { ICreateLoginReturnValue } from '../../../../components/CreateLoginModal';
import { useCompanionApplicationContext } from '../../../../contexts/CompanionApplicationContext';
import useVaultsToEmployeeAccountsAPI from '../../../../hooks/useVaultsToEmployeeAccountsAPI';
import {
  useDeleteVaultsToEmployeeAccounts,
  useGetEmployeeAccountById,
} from '../../../../hooks/reactQuery';
import EmployeeLoginsTabFilter, {
  IEmployeeLoginsTabFilterSubmitResult,
} from './components/EmployeeLoginsTabFilter';
import ShareVaultsToGroupModal from '../../../../components/ShareVaultsToGroupModal';
import useVaultsAPI from '../../../../hooks/useVaultsAPI';
import VaultTypeEnum from '../../../../enums/VaultTypeEnum';
import { logException } from '../../../../services/sentryService';
import { getActiveOrganizationId } from '../../../../services/organizationService';
import HostTypeEnum from '../../../../enums/HostTypeEnum';
import { generateTooltipTitle } from '../../../../helpers/tooltips';
import { useTrustedPortalStore } from '../../../../modules/TrustedPortalModule/store';
import MoveVaultsToEmployeeModal from '../../../../components/MoveVaultsToEmployeeModal';
import MoveVaultsToGroupModal from '../../../../components/MoveVaultsToGroupModal';
import { getTranslationKeyByError } from '../../../../helpers/errorService';

interface IEmployeeLoginsTabProps {
  employeeAccountId: string;
  employeeAccountStatus: EmployeeAccountStatus;
}

const EmployeeLoginsTab = (props: IEmployeeLoginsTabProps) => {
  const { employeeAccountId, employeeAccountStatus } = props;
  const { t } = useTranslations();
  const [isCreateLoginLoading, setIsCreateLoginLoading] = useState(false);
  const [isDeleteLoginLoading, setIsDeleteLoginLoading] = useState(false);
  const [isMoveToPrivateLoginsLoading, setIsMoveToPrivateLoginsLoading] = useState(false);
  const [isMoveToPrivateLoginsModalOpen, setIsMoveToPrivateLoginsModalOpen] = useState(false);
  const [isShareLoginsModalOpen, setIsShareLoginsModalOpen] = useState(false);
  const [isCreateLoginModalOpen, setIsCreateLoginModalOpen] = useState(false);
  const [isDeleteLoginModalOpen, setIsDeleteLoginModalOpen] = useState(false);
  const [isMoveToEmployeeLoginsModalOpen, setIsMoveToEmployeeLoginsModalOpen] = useState(
    false,
  );
  const [isMoveToGroupLoginsModalOpen, setIsMoveToGroupLoginsModalOpen] = useState(
    false,
  );

  const {
    isOpen: isFilterOpen,
    anchorEl: filterAnchorEl,
    setPopperIsOpen: setIsFilterOpen,
  } = usePopper();
  const toggleIsFilterOpen = useCallback<NonNullable<IFilterButtonProps['onChange']>>(
    (event) => setIsFilterOpen(!isFilterOpen, event),
    [setIsFilterOpen, isFilterOpen],
  );
  const handleFilterClose = useCallback(() => setIsFilterOpen(false), [setIsFilterOpen]);
  const handleMoveToPrivateLoginsModalOpen = useCallback(
    () => setIsMoveToPrivateLoginsModalOpen(true),
    [],
  );
  const handleMoveToPrivateLoginsModalClose = useCallback(
    () => setIsMoveToPrivateLoginsModalOpen(false),
    [],
  );
  const handleCreateLoginModalOpen = useCallback(() => setIsCreateLoginModalOpen(true), []);
  const handleCreateLoginModalClose = useCallback(() => setIsCreateLoginModalOpen(false), []);
  const handleDeleteLoginModalOpen = useCallback(() => setIsDeleteLoginModalOpen(true), []);
  const handleDeleteLoginModalClose = useCallback(() => setIsDeleteLoginModalOpen(false), []);
  const handleShareLoginsModalOpen = useCallback(() => setIsShareLoginsModalOpen(true), []);
  const handleShareLoginsModalClose = useCallback(() => setIsShareLoginsModalOpen(false), []);
  const handleMoveToEmployeeLoginsModalOpen = useCallback(
    () => setIsMoveToEmployeeLoginsModalOpen(true),
    [],
  );
  const handleMoveToEmployeeLoginsModalClose = useCallback(
    () => setIsMoveToEmployeeLoginsModalOpen(false),
    [],
  );
  const handleMoveToGroupLoginsModalOpen = useCallback(
    () => setIsMoveToGroupLoginsModalOpen(true),
    [],
  );
  const handleMoveToGroupLoginsModalClose = useCallback(
    () => setIsMoveToGroupLoginsModalOpen(false),
    [],
  );

  const {
    selectedLogins,
    searchQuery,
    setSearchQuery,
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    resetActivePage,
    resetSelectedRows,
    ...restTableProps
  } = useEmployeeGroupLoginsTable({
    persistentFilters: { employeeAccountId },
    noDataMessageKey: 'loginsTab.table.noData',
  });
  const { data: employeeAccount } = useGetEmployeeAccountById(
    { employeeAccountId },
  );
  const {
    values: selectedLoginsAsObjects, keys: selectedLoginsIds,
  } = useMapKeyValueExtractor<IEmployeeGroupLoginsTableRow>(selectedLogins);

  const handleSearchChange = useCallback<ISearchableTextFieldProps['onChange']>(
    (debouncedValue) => {
      setSearchQuery(debouncedValue);
      resetActivePage();
    },
    [setSearchQuery, resetActivePage],
  );

  const handleFilterSubmit = useCallback((updatedValues: IEmployeeLoginsTabFilterSubmitResult) => {
    setFilterValues(updatedValues);
    resetActivePage();
  }, [setFilterValues, resetActivePage]);
  const { showError, showWarning, showSuccess } = useHeroSnackbar();
  const { symmetricKey } = useCompanionApplicationContext();
  const { createVaultsToEmployeeAccounts } = useVaultsToEmployeeAccountsAPI();
  const { moveVaultsToPrivateKeychain } = useVaultsAPI();
  const { mutate: mutateDeleteLogin } = useDeleteVaultsToEmployeeAccounts();

  const activeOrganizationId = getActiveOrganizationId();
  const isTrustedPortalEnabled = useTrustedPortalStore.useIsEnabledByOrganizationId()[
    activeOrganizationId!
  ] ?? false;

  const handleCreateLogin = useCallback(async (params: ICreateLoginReturnValue) => {
    const {
      name, additionalInfo, username, password, hostType, website, desktopApp,
    } = params;
    setIsCreateLoginLoading(true);
    try {
      let payload = {
        name,
        username,
        additionalInfo,
        employeeAccountId,
        vaultType: VaultTypeEnum.Password,
        password: encryptSymmetric({
          key: symmetricKey,
          string: password,
          fallbackValue: null,
        }),
      };
      if (hostType === HostTypeEnum.Website) {
        payload = { ...payload, ...(website && { webAddress: website }) };
      }
      if (hostType === HostTypeEnum.DesktopApp) {
        payload = {
          ...payload,
          ...(desktopApp?.value && {
            desktopApplicationName: desktopApp.value,
            applicationId: desktopApp.id,
          }),
        };
      }
      await createVaultsToEmployeeAccounts(payload);
      if (isTrustedPortalEnabled) {
        showSuccess({
          text: t('trustedPortalSuccessNotifications.login.created'),
        });
      } else {
        showWarning({
          text: t('createLoginModal.approvalOnMobileToast'),
        });
      }
      handleCreateLoginModalClose();
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'EmployeeLoginsTab/handleCreateLogin exception',
      });
    } finally {
      setIsCreateLoginLoading(false);
    }
  }, [
    employeeAccountId,
    symmetricKey,
    handleCreateLoginModalClose,
    createVaultsToEmployeeAccounts,
    isTrustedPortalEnabled,
    showSuccess,
    showWarning,
    showError,
    t,
  ]);

  const handleDeleteLogin = useCallback(async () => {
    setIsDeleteLoginLoading(true);
    mutateDeleteLogin({
      vaultIds: selectedLoginsIds, employeeAccountId,
    }, {
      onSuccess: ({ failCount, successCount }) => {
        if (successCount) {
          showSuccess({
            text: t('deleteLoginModal.successMessage', { count: successCount }),
          });
        }
        if (failCount) {
          showError({
            text: t('deleteLoginModal.errorMessage', { count: failCount }),
          });
        }
        handleDeleteLoginModalClose();
        resetSelectedRows();
        resetActivePage();
      },
      onError: (e) => {
        showError({ text: t(getTranslationKeyByError(e)) });
        logException(e, {
          message: 'EmployeeLoginsTab/handleDeleteLogin exception',
        });
      },
      onSettled: () => setIsDeleteLoginLoading(false),
    });
  }, [
    employeeAccountId,
    selectedLoginsIds,
    mutateDeleteLogin,
    handleDeleteLoginModalClose,
    showSuccess,
    showError,
    resetSelectedRows,
    resetActivePage,
    t,
  ]);

  const handleMoveToPrivateLogins = useCallback(async () => {
    try {
      setIsMoveToPrivateLoginsLoading(true);
      await moveVaultsToPrivateKeychain({ vaultIds: selectedLoginsIds });
      if (isTrustedPortalEnabled) {
        showSuccess({
          text: t('trustedPortalSuccessNotifications.login.moved'),
        });
      } else {
        showWarning({
          text: t('moveLoginsModal.approvalOnMobileToast'),
        });
      }
      resetSelectedRows();
      handleMoveToPrivateLoginsModalClose();
    } catch (e) {
      showError({ text: t(getTranslationKeyByError(e)) });
      logException(e, {
        message: 'EmployeeLoginsTab/handleMoveToPrivateLogins exception',
      });
    } finally {
      setIsMoveToPrivateLoginsLoading(false);
    }
  }, [
    moveVaultsToPrivateKeychain,
    selectedLoginsIds,
    isTrustedPortalEnabled,
    showWarning,
    showError,
    showSuccess,
    handleMoveToPrivateLoginsModalClose,
    resetSelectedRows,
    t,
  ]);

  const {
    isMoveToPrivateDisabled,
    isMoveToEmployeeDisabled,
    isMoveToGroupDisabled,
    isShareDisabled,
    isRemoveDisabled,
  } = useMemo(() => {
    if (!selectedLoginsAsObjects.length) {
      return {
        isMoveToPrivateDisabled: true,
        isMoveToEmployeeDisabled: true,
        isMoveToGroupDisabled: true,
        isShareDisabled: true,
        isRemoveDisabled: true,
      };
    }
    let disableMoveToPrivate = false;
    let disableShare = false;
    let disableRemove = false;
    selectedLoginsAsObjects.forEach(({ ownership }) => {
      if (ownership === Ownership.Groups) {
        disableMoveToPrivate = true;
        disableRemove = true;
      }
      if (ownership === Ownership.Employees) {
        disableShare = true;
      }
    });
    return {
      isMoveToPrivateDisabled: disableMoveToPrivate,
      isMoveToEmployeeDisabled: false,
      isMoveToGroupDisabled: false,
      isShareDisabled: disableShare,
      isRemoveDisabled: disableRemove,
    };
  }, [selectedLoginsAsObjects]);

  const moveLoginsToPrivateModalDescriptionElement = useMemo(
    () => (
      <>
        <Typography>{t('moveLoginsToPrivateKeychainModal.description')}</Typography>
        <Typography>{t('moveLoginsToPrivateKeychainModal.additionalDescription')}</Typography>
      </>
    ),
    [t],
  );

  const {
    moveToPrivateTooltipTitle,
    moveToEmployeeTooltipTitle,
    moveToGroupTooltipTitle,
    shareTooltipTitle,
    removeTooltipTitle,
  } = useMemo(() => {
    const moveToPrivateTitle = generateTooltipTitle({
      selectedDataLength: selectedLoginsAsObjects.length,
      t,
      isDisabled: isMoveToPrivateDisabled,
      key: 'loginsTab.moveToPrivate',
    });
    const moveToEmployeeTitle = generateTooltipTitle({
      selectedDataLength: selectedLoginsAsObjects.length,
      t,
      isDisabled: isMoveToEmployeeDisabled,
      key: 'loginsTab.moveToEmployee',
    });
    const moveToGroupTitle = generateTooltipTitle({
      selectedDataLength: selectedLoginsAsObjects.length,
      t,
      isDisabled: isMoveToGroupDisabled,
      key: 'loginsTab.moveToGroup',
    });
    const shareTitle = generateTooltipTitle({
      selectedDataLength: selectedLoginsAsObjects.length,
      t,
      isDisabled: isShareDisabled,
      key: 'loginsTab.share',
    });
    const removeTitle = generateTooltipTitle({
      selectedDataLength: selectedLoginsAsObjects.length,
      t,
      isDisabled: isRemoveDisabled,
      key: 'loginsTab.remove',
    });

    return {
      moveToPrivateTooltipTitle: moveToPrivateTitle,
      moveToEmployeeTooltipTitle: moveToEmployeeTitle,
      moveToGroupTooltipTitle: moveToGroupTitle,
      shareTooltipTitle: shareTitle,
      removeTooltipTitle: removeTitle,
    };
  }, [
    selectedLoginsAsObjects.length,
    t,
    isMoveToPrivateDisabled,
    isShareDisabled,
    isRemoveDisabled,
    isMoveToEmployeeDisabled,
    isMoveToGroupDisabled,
  ]);

  const isCreateLoginShown = useMemo(() => (
    employeeAccountStatus !== EmployeeAccountStatus.Archived
  ), [employeeAccountStatus]);

  const isMoveToPrivateLoginShown = useMemo(() => (
    employeeAccountStatus !== EmployeeAccountStatus.Archived
    && employeeAccountStatus !== EmployeeAccountStatus.Staged
    && employeeAccountStatus !== EmployeeAccountStatus.Invited
    && employeeAccountStatus !== EmployeeAccountStatus.Migrated
    && employeeAccountStatus !== EmployeeAccountStatus.MigrationInvited
  ), [employeeAccountStatus]);

  return (
    <PanelContent p={0}>
      <Grid container justifyContent="space-between" alignItems="stretch" p={1}>
        <Grid item xs={4} container flexWrap="nowrap" spacing={1}>
          <Grid item>
            <Tooltip title={t('common.filter')}>
              <FilterButton
                isFilterActive={isFilterActive}
                numberOfActiveFilters={numberOfActiveFilters}
                selected={isFilterOpen}
                onChange={toggleIsFilterOpen}
              />
            </Tooltip>
          </Grid>
          <Grid item my={0.5}>
            <Divider orientation="vertical" />
          </Grid>
          {isMoveToPrivateLoginShown && (
            <Grid item alignSelf="center">
              <Tooltip title={moveToPrivateTooltipTitle}>
                <ActionButton
                  width={40}
                  height={40}
                  onClick={handleMoveToPrivateLoginsModalOpen}
                  disabled={isMoveToPrivateDisabled}
                  variant={SeverityEnum.Error}
                >
                  <MoveIcon />
                </ActionButton>
              </Tooltip>
            </Grid>
          )}
          <Grid item alignSelf="center">
            <Tooltip title={moveToEmployeeTooltipTitle}>
              <ActionButton
                width={40}
                height={40}
                onClick={handleMoveToEmployeeLoginsModalOpen}
                disabled={isMoveToEmployeeDisabled}
              >
                <MoveToEmployeeIcon />
              </ActionButton>
            </Tooltip>
          </Grid>
          <Grid item alignSelf="center">
            <Tooltip title={moveToGroupTooltipTitle}>
              <ActionButton
                width={40}
                height={40}
                onClick={handleMoveToGroupLoginsModalOpen}
                disabled={isMoveToGroupDisabled}
              >
                <MoveToGroupIcon />
              </ActionButton>
            </Tooltip>
          </Grid>
          <Grid item alignSelf="center">
            <Tooltip title={shareTooltipTitle}>
              <ActionButton
                width={40}
                height={40}
                onClick={handleShareLoginsModalOpen}
                disabled={isShareDisabled}
              >
                <ShareIcon />
              </ActionButton>
            </Tooltip>
          </Grid>
          <Grid item alignSelf="center">
            <Tooltip title={removeTooltipTitle}>
              <ActionButton
                width={40}
                height={40}
                onClick={handleDeleteLoginModalOpen}
                disabled={isRemoveDisabled}
                variant={SeverityEnum.Error}
              >
                <ErrorIcon />
              </ActionButton>
            </Tooltip>
          </Grid>
          <Grid item my={0.5}>
            <Divider orientation="vertical" />
          </Grid>
        </Grid>
        <Grid item xs={8} container justifyContent="flex-end" flexWrap="nowrap">
          <Grid item>
            <SearchableTextField
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder={t('common.search')}
            />
          </Grid>
          {isCreateLoginShown && (
            <Grid item ml={3}>
              <Button
                icon={<PlusIcon />}
                onClick={handleCreateLoginModalOpen}
              >
                {t('loginsTab.createLoginButton')}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Divider />
      <EmployeeLoginsTable
        selectedLogins={selectedLogins}
        {...restTableProps}
      />
      <EmployeeLoginsTabFilter
        isOpen={isFilterOpen}
        anchorEl={filterAnchorEl}
        onSubmit={handleFilterSubmit}
        onClose={handleFilterClose}
        initialValues={filterValues as IEmployeeLoginsTabFilterSubmitResult}
      />
      {isCreateLoginModalOpen && (
        <CreateLoginModal
          isOpen={isCreateLoginModalOpen}
          onClose={handleCreateLoginModalClose}
          onSubmit={handleCreateLogin}
          isLoading={isCreateLoginLoading}
        />
      )}
      {isDeleteLoginModalOpen && (
        <PromptModal
          open={isDeleteLoginModalOpen}
          onClose={handleDeleteLoginModalClose}
          onSubmit={handleDeleteLogin}
          title={t(
            'deleteLoginModal.deleteFromEmployee.title',
            { count: selectedLogins.size },
          )}
          description={t(
            'deleteLoginModal.deleteFromEmployee.description',
            { email: employeeAccount?.email },
          )}
          additionalDescription={t(
            'deleteLoginModal.deleteFromEmployee.additionalDescription',
          )}
          list={selectedLoginsAsObjects}
          renderField="name"
          renderKey="vaultId"
          isLoading={isDeleteLoginLoading}
        />
      )}
      {isMoveToPrivateLoginsModalOpen && (
        <PromptModal
          open={isMoveToPrivateLoginsModalOpen}
          onClose={handleMoveToPrivateLoginsModalClose}
          onSubmit={handleMoveToPrivateLogins}
          title={t('moveLoginsToPrivateKeychainModal.title', { count: selectedLogins.size })}
          description={moveLoginsToPrivateModalDescriptionElement}
          list={selectedLoginsAsObjects}
          renderField="name"
          renderKey="vaultId"
          isLoading={isMoveToPrivateLoginsLoading}
          approvalButtonText="common.move"
          approvalButtonColor="primary"
        />
      )}
      {isMoveToEmployeeLoginsModalOpen && (
        <MoveVaultsToEmployeeModal
          isOpen={isMoveToEmployeeLoginsModalOpen}
          onClose={handleMoveToEmployeeLoginsModalClose}
          vaults={selectedLoginsAsObjects}
          onSubmit={resetSelectedRows}
          type={VaultTypeEnum.Password}
        />
      )}
      {isMoveToGroupLoginsModalOpen && (
        <MoveVaultsToGroupModal
          isOpen={isMoveToGroupLoginsModalOpen}
          onClose={handleMoveToGroupLoginsModalClose}
          vaults={selectedLoginsAsObjects}
          onSubmit={resetSelectedRows}
          type={VaultTypeEnum.Password}
        />
      )}
      {isShareLoginsModalOpen && (
        <ShareVaultsToGroupModal
          isOpen={isShareLoginsModalOpen}
          onClose={handleShareLoginsModalClose}
          vaults={selectedLoginsAsObjects}
          type={VaultTypeEnum.Password}
          onSubmit={resetSelectedRows}
        />
      )}
    </PanelContent>
  );
};

export default memo(EmployeeLoginsTab);
