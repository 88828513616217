import { useCallback } from 'react';
import { upperFirst } from 'lodash';
import {
  useHeroSnackbar,
  useTable,
  useQueryTableFetch,
  useTranslations,
  useTableLocalization,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  usePubSub,
  buildTablePreserverDecoderConfigItem,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  TUseTablePreserverDecoderConfigs,
  TablePreserverDecoderConfigItemTypeEnum,
  TSetSearchParams,
} from '@uniqkey-frontend/shared-app';
import {
  GetPartnersAccessibleToCurrentOrganizationOrderProperty,
} from '@uniqkey-backend-organization-web/api-client';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import {
  IGetPartnersAccessParams,
  TGetPartnersAccessMethod,
} from '../../useOrganizationSettingsAPI/interfaces';
import useOrganizationSettingsAPI from '../../useOrganizationSettingsAPI';
import { IPartnersWithAccessTableRow }
  from '../../../components/tables/PartnersWithAccessTable';

export const REACT_QUERY_PARTNERS_WITH_ACCESS_KEY = [ReactQueryKeyEnum.PartnersWithAccess];

export interface IUsePartnersWithAccessTableParams {
  noDataMessageKey: string;
}

export interface IUsePartnersWithAccessTableReturn extends
  Omit<IUseTableReturn<IPartnersWithAccessTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage' | 'resetSelectedRows'>,
  Omit<IUseQueryTableFetchReturn<TGetPartnersAccessMethod>, 'data' | 'isFetchedAfterMount'>
{
  partners: IUseQueryTableFetchReturn<TGetPartnersAccessMethod>['data'];
  selectedPartners: IUseTableReturn<IPartnersWithAccessTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

interface ITablePreservationConfigs extends
  Omit<IGetPartnersAccessParams,
    'page' | 'pageLength' | 'searchQuery' | 'orderPropertyName' | 'isDescending'
  >,
  Pick<IUseTableReturn<IPartnersWithAccessTableRow>, 'columnOrderBy' | 'columnOrderDirection'>
{
  activePage: IGetPartnersAccessParams['page'];
  perPage: IGetPartnersAccessParams['pageLength'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
  columnOrderBy: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: GetPartnersAccessibleToCurrentOrganizationOrderProperty },
  ),
  columnOrderDirection: true,
};

const usePartnersWithAccessTable = (
  params: IUsePartnersWithAccessTableParams,
): IUsePartnersWithAccessTableReturn => {
  const {
    noDataMessageKey,
  } = params;

  const queryClient = useQueryClient();
  const { t } = useTranslations();
  const { showError } = useHeroSnackbar();
  const { getPartnersAccess } = useOrganizationSettingsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
    columnOrderBy: initialColumnOrderBy,
    columnOrderDirection: initialColumnOrderDirection,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedRows: selectedPartners,
    resetTableMethods,
    ...restTableProps
  } = useTable<IPartnersWithAccessTableRow>({
    initialActivePage,
    initialPerPage,
    initialColumnOrderBy,
    initialColumnOrderDirection,
  });

  const {
    resetTable,
    resetActivePage,
    resetSelectedRows,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: partners, isLoading, isFetchedAfterMount, total, resetQuery,
  } = useQueryTableFetch({
    queryKey: REACT_QUERY_PARTNERS_WITH_ACCESS_KEY,
    queryClient,
    request: getPartnersAccess,
    params: {
      page: activePage,
      pageLength: perPage,
      orderPropertyName: GetPartnersAccessibleToCurrentOrganizationOrderProperty[
        upperFirst(
          columnOrderBy,
        ) as keyof typeof GetPartnersAccessibleToCurrentOrganizationOrderProperty
      ],
      isDescending: columnOrderDirection ? columnOrderDirection === 'desc' : undefined,
    },
    onRequestError: handleOnRequestError,
    autoRefetch: true,
  });

  const { localization } = useTableLocalization({
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
  });

  return {
    partners,
    isLoading,
    total,
    resetQuery,
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedPartners,
    localization,
    resetActivePage,
    resetSelectedRows,
    ...restTableProps,
  };
};

export default usePartnersWithAccessTable;
