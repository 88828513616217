import {
  BaseEitherError,
  Either,
  IExtensionCommunicationDecodedPayload,
} from '@uniqkey-frontend/shared-app';
import { OnboardingUnknownError, TOnboardingErrors } from '../../common/errors';
import Operations from '../../operations';
import Helpers from '../../helpers';

interface IStartParams {
  decoded: IExtensionCommunicationDecodedPayload;
  hash: string;
}

const start = async (
  params: IStartParams,
): Promise<Either<void, TOnboardingErrors>> => {
  try {
    Helpers.setIsInProgress(true);
    const { decoded, hash } = params;
    const { email, code } = decoded;
    const verificationResult = await Operations.verifyCredentialsOperation({
      email,
      code,
    });
    if (verificationResult instanceof BaseEitherError) {
      return verificationResult;
    }
    const initialCheckResult = await Operations.initialExtensionCheckOperation();
    if (initialCheckResult instanceof BaseEitherError) {
      return initialCheckResult;
    }
    const { isInstalled, extensionId } = initialCheckResult;
    if (!isInstalled) {
      const waitUntilInstalledResult = await Operations.waitUntilInstalledOperation({
        extensionId,
      });
      if (waitUntilInstalledResult instanceof BaseEitherError) {
        return waitUntilInstalledResult;
      }
    }
    const transferDataResult = await Operations.transferDataOperation({
      extensionId,
      hash,
    });
    return transferDataResult;
  } catch (e) {
    return new OnboardingUnknownError();
  } finally {
    Helpers.setIsInProgress(false);
  }
};

const isInProgress = () => Helpers.getIsInProgress();

export default {
  start,
  isInProgress,
};
