import { Link } from 'react-router-dom';
import {
  Typography,
  Grid,
  G600,
  S600,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import PageRouteEnum from '../../../../enums/PageRouteEnum';

const LINK_STYLE = { color: S600 };

const SupportJumpErrorBlock = () => {
  const { t } = useTranslations();

  return (
    <Grid container item flexDirection="column" alignItems="center" rowGap={3} mt={3}>
      <Grid item textAlign="center">
        <Typography variant="body1" color={G600}>
          {t('supportJumpPage.error.message')}
        </Typography>
      </Grid>
      <Grid item>
        <Link style={LINK_STYLE} to={PageRouteEnum.Login} replace>
          <Typography variant="subtitle1">
            {t('supportJumpPage.error.link')}
          </Typography>
        </Link>
      </Grid>
    </Grid>
  );
};

export default SupportJumpErrorBlock;
