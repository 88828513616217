import { memo } from 'react';
import { isNumber } from 'lodash';
import {
  Box,
  Grid,
  List,
  ListItem,
  G600,
  G900,
  SecurityScoreWidget,
  Typography,
  useTranslations,
  WidgetContainer,
} from '@uniqkey-frontend/shared-app';
import { useGetGroupById } from '../../../../hooks/reactQuery';

interface IGroupDetailsSecurityScoreWidgetProps {
  groupId: string;
}

const LIST_SX = { listStyleType: 'disc', paddingLeft: 3 };
const LIST_ITEM_SX = { display: 'list-item' };

const GroupDetailsSecurityScoreWidget = (props: IGroupDetailsSecurityScoreWidgetProps) => {
  const { groupId } = props;
  const { t } = useTranslations();
  const {
    data, isLoading, isError,
  } = useGetGroupById({ groupId });
  const { groupSecurityScore } = data ?? {};

  if (isError) {
    return null;
  }

  if (!isNumber(groupSecurityScore)) {
    return (
      <WidgetContainer withShadow isLoading={isLoading}>
        <Typography variant="subtitle1" color={G900}>{t('common.securityScore')}</Typography>
        <Typography variant="caption" color={G600}>
          {t('groupDetailsSecurityScoreWidget.noScore')}
        </Typography>
      </WidgetContainer>
    );
  }

  return (
    <WidgetContainer withShadow isLoading={isLoading}>
      <Grid item container justifyContent="space-between" wrap="nowrap">
        <Grid item>
          <Typography variant="subtitle1" color={G900}>
            {t('groupDetailsSecurityScoreWidget.title')}
          </Typography>
          <Box mt={2} />
          <Typography variant="caption" color={G900}>
            {t('groupDetailsSecurityScoreWidget.calculatedAsAverage')}
            :
          </Typography>
          <List sx={LIST_SX}>
            <ListItem sx={LIST_ITEM_SX}>
              <Typography variant="body2" color={G600}>
                {t('groupDetailsSecurityScoreWidget.uniqkeyAccessScore')}
              </Typography>
            </ListItem>
            <ListItem sx={LIST_ITEM_SX}>
              <Typography variant="body2" color={G600}>
                {t('groupDetailsSecurityScoreWidget.securityScore')}
              </Typography>
            </ListItem>
          </List>
        </Grid>
        <Grid item>
          <Box pl={2} pb={2} pr={4} pt={4} display="flex" justifyContent="center">
            <SecurityScoreWidget percentage={Math.floor(groupSecurityScore)} t={t} />
          </Box>
        </Grid>
      </Grid>
    </WidgetContainer>
  );
};

export default memo(GroupDetailsSecurityScoreWidget);
