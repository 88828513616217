import { BaseEitherError } from '@uniqkey-frontend/shared-app';
import {
  TOnboardingVerifyCredentialsOperationErrors,
} from '../operations/verifyCredentialsOperation/errors';
import {
  TOnboardingInitialExtensionCheckOperationErrors,
} from '../operations/initialExtensionCheckOperation/errors';
import { TOnboardingTransferDataOperationErrors } from '../operations/transferDataOperation/errors';

export class OnboardingUnknownError extends BaseEitherError {}

export type TOnboardingErrors = OnboardingUnknownError
  | TOnboardingVerifyCredentialsOperationErrors
  | TOnboardingInitialExtensionCheckOperationErrors
  | TOnboardingTransferDataOperationErrors;
