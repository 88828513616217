import { useCallback, useMemo } from 'react';
import {
  PanelContent,
  Grid,
  ActionButton,
  ApproveIcon,
  CloseIcon,
  Divider,
  Tooltip,
  useTranslations,
  useHeroSnackbar,
  useMapKeyValueExtractor,
  FilterButton,
  usePopper,
  IFilterButtonProps,
  SeverityEnum,
} from '@uniqkey-frontend/shared-app';
import { PartnerSupportRequestStatusName } from '@uniqkey-backend-organization-web/api-client';
import PartnerAccessRequestsTable, {
  IPartnerAccessRequestsTableRow,
} from '../../../../components/tables/PartnerAccessRequestsTable';
import usePartnerAccessRequestsTable from '../../../../hooks/tables/usePartnerAccessRequestsTable';
import { logException } from '../../../../services/sentryService';
import usePartnerSupportRequestsAPI from '../../../../hooks/usePartnerSupportRequestsAPI';
import { getActiveOrganizationId } from '../../../../services/organizationService';
import { useTrustedPortalStore } from '../../../../modules/TrustedPortalModule/store';
import PartnerAccessRequestsListFilter, {
  IPartnerAccessRequestsListFilterSubmitResult,
} from './components/PartnerAccessRequestsListFilter';

const PartnerAccessRequestsTab = () => {
  const { showSuccess, showError, showWarning } = useHeroSnackbar();
  const { t } = useTranslations();
  const {
    approvePartnerAccessRequest,
    rejectPartnerAccessRequest,
  } = usePartnerSupportRequestsAPI();

  const {
    selectedPartnerAccessRequests,
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    resetQuery,
    resetActivePage,
    resetSelectedRows,
    ...restTableProps
  } = usePartnerAccessRequestsTable({
    noDataMessageKey: 'table.noData.default',
  });

  const activeOrganizationId = getActiveOrganizationId();
  const isTrustedPortalEnabled = useTrustedPortalStore.useIsEnabledByOrganizationId()[
    activeOrganizationId!
  ] ?? false;

  const {
    values: selectedPartnerAccessRequestsAsObjects, keys: selectedPartnerAccessRequestsIds,
  } = useMapKeyValueExtractor<IPartnerAccessRequestsTableRow>(selectedPartnerAccessRequests);

  const isPending = useMemo(() => (
    !!selectedPartnerAccessRequestsAsObjects.length && selectedPartnerAccessRequestsAsObjects.every(
      (request) => request.requestStatus === PartnerSupportRequestStatusName.Pending,
    )
  ), [selectedPartnerAccessRequestsAsObjects]);

  const handleApprovePartnerAccess = useCallback(async () => {
    try {
      const {
        successCount,
        failedCount,
      } = await approvePartnerAccessRequest(
        { partnerSupportRequestIds: selectedPartnerAccessRequestsIds },
      );
      if (successCount) {
        if (isTrustedPortalEnabled) {
          showSuccess({
            text: t('trustedPortalSuccessNotifications.partnerAccessRequestApproved'),
          });
        } else {
          showWarning({
            text: t(
              'requestsPage.partnerAccessRequestsTab.approvePartnerAccessRequest.successMessage',
              { count: successCount },
            ),
          });
        }
      }
      if (failedCount) {
        showError({
          text: t(
            'requestsPage.partnerAccessRequestsTab.approvePartnerAccessRequest.errorMessage',
            { count: failedCount },
          ),
        });
      }
      resetSelectedRows();
      resetActivePage();
      resetQuery();
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'PartnerAccessRequestsTab/handleApprovePartnerAccess exception',
      });
    }
  }, [
    approvePartnerAccessRequest,
    resetQuery,
    selectedPartnerAccessRequestsIds,
    resetSelectedRows,
    resetActivePage,
    isTrustedPortalEnabled,
    showSuccess,
    showWarning,
    showError,
    t,
  ]);

  const handleRejectPartnerAccess = useCallback(async () => {
    try {
      const {
        successCount,
        failedCount,
      } = await rejectPartnerAccessRequest(
        { partnerSupportRequestIds: selectedPartnerAccessRequestsIds },
      );
      if (successCount) {
        showSuccess({
          text: t(
            'requestsPage.partnerAccessRequestsTab.rejectPartnerAccessRequest.successMessage',
            { count: successCount },
          ),
        });
      }
      if (failedCount) {
        showError({
          text: t(
            'requestsPage.partnerAccessRequestsTab.rejectPartnerAccessRequest.errorMessage',
            { count: failedCount },
          ),
        });
      }
      resetSelectedRows();
      resetActivePage();
      resetQuery();
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'PartnerAccessRequestsTab/handleRejectPartnerAccess exception',
      });
    }
  }, [
    rejectPartnerAccessRequest,
    resetQuery,
    selectedPartnerAccessRequestsIds,
    resetSelectedRows,
    resetActivePage,
    showSuccess,
    showError,
    t,
  ]);

  const {
    isOpen: isFilterOpen,
    anchorEl: filterAnchorEl,
    setPopperIsOpen: setIsFilterOpen,
  } = usePopper();
  const toggleIsFilterOpen = useCallback<NonNullable<IFilterButtonProps['onChange']>>(
    (event) => setIsFilterOpen(!isFilterOpen, event),
    [setIsFilterOpen, isFilterOpen],
  );
  const handleFilterClose = useCallback(() => setIsFilterOpen(false), [setIsFilterOpen]);

  const handleFilterSubmit = useCallback((
    updatedValues: IPartnerAccessRequestsListFilterSubmitResult,
  ) => {
    setFilterValues(updatedValues);
    resetActivePage();
  }, [setFilterValues, resetActivePage]);

  return (
    <PanelContent p={0}>
      <Grid container justifyContent="space-between" alignItems="stretch" p={1}>
        <Grid item xs={4} container flexWrap="nowrap" spacing={1}>
          <Grid item>
            <Tooltip title={t('common.filter')}>
              <FilterButton
                isFilterActive={isFilterActive}
                numberOfActiveFilters={numberOfActiveFilters}
                selected={isFilterOpen}
                onChange={toggleIsFilterOpen}
              />
            </Tooltip>
          </Grid>
          {(!!selectedPartnerAccessRequests.size && isPending) && (
            <>
              <Grid item my={0.5}>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item alignSelf="center">
                <Tooltip title={t('requestsPage.partnerAccessRequestsTab.approve')}>
                  <ActionButton
                    width={40}
                    height={40}
                    onClick={handleApprovePartnerAccess}
                  >
                    <ApproveIcon />
                  </ActionButton>
                </Tooltip>
              </Grid>
              <Grid item alignSelf="center">
                <Tooltip title={t('requestsPage.partnerAccessRequestsTab.reject')}>
                  <ActionButton
                    width={40}
                    height={40}
                    variant={SeverityEnum.Error}
                    onClick={handleRejectPartnerAccess}
                  >
                    <CloseIcon />
                  </ActionButton>
                </Tooltip>
              </Grid>
              <Grid item my={0.5}>
                <Divider orientation="vertical" />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Divider />
      <PartnerAccessRequestsTable
        selectedPartnerAccessRequests={selectedPartnerAccessRequests}
        {...restTableProps}
      />
      <PartnerAccessRequestsListFilter
        isOpen={isFilterOpen}
        anchorEl={filterAnchorEl}
        onSubmit={handleFilterSubmit}
        onClose={handleFilterClose}
        initialValues={filterValues}
      />
    </PanelContent>
  );
};

export default PartnerAccessRequestsTab;
