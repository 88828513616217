import { useCallback, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import {
  Box,
  CompanionApplicationEventTypeEnum,
  getBrowserName,
  getOSName,
  Grid,
  HeroLogoWithTextIcon,
  QrCode,
  CopyableText,
  Divider,
  RefreshIcon,
  Button,
  G900,
  G600,
  Typography,
  useHeroSnackbar,
  useTranslations,
  decryptAsymmetric,
  ScrollablePanel,
  generateAsymmetricKeys,
  type IKeyPair,
} from '@uniqkey-frontend/shared-app';
import {
  CompanionApplicationTypeName,
  OrganizationPortalAuthenticated,
  OrganizationPortalAuthenticatedV2,
} from '@uniqkey-backend-organization-web/api-client';
import copy from 'copy-to-clipboard';
import { useUser } from '../../contexts/UserContext';
import PageRouteEnum from '../../enums/PageRouteEnum';
import { login } from '../../services/authService';
import { useCompanionApplicationContext } from '../../contexts/CompanionApplicationContext';
import RealtimeAPIEventTypeEnum from '../../enums/RealtimeAPIEventTypeEnum';
import { logException } from '../../services/sentryService';
import { subscribeToRealtimeAPIEvent } from '../../services/webSocketsManager';
import usePairingCodesAPI from '../../hooks/usePairingCodesAPI';

const LoginPage = () => {
  const [pairingCode, setPairingCode] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [asymmetricKeys, setAsymmetricKeys] = useState<Required<IKeyPair> | null>(null);
  const [qrCodeData, setQrCodeData] = useState('');

  const { isAuthenticated } = useUser();
  const { guid, symmetricKey } = useCompanionApplicationContext();
  const { t } = useTranslations();
  const { showSuccess, showError } = useHeroSnackbar();
  const { generatePairingCode } = usePairingCodesAPI();

  useEffect(() => {
    if (asymmetricKeys) {
      return;
    }
    const generateEphemeralKeys = async () => {
      const keys = await generateAsymmetricKeys();
      setAsymmetricKeys(keys);
    };
    generateEphemeralKeys();
  }, [asymmetricKeys]);

  const handleCopyToClipboard = useCallback((passwordToCopy: string) => {
    copy(passwordToCopy);
    showSuccess({ text: t('common.copiedToClipboard') });
  }, [showSuccess, t]);

  const handleGenerateCode = useCallback(async () => {
    try {
      if (!asymmetricKeys) {
        return;
      }
      setIsLoading(true);
      const { code } = await generatePairingCode({
        companionApplicationId: guid,
        companionApplicationKeyPairPublic: asymmetricKeys.publicKey,
        os: getOSName(),
        browserType: getBrowserName(),
      });
      setPairingCode(code);
    } catch (e) {
      logException(e, { message: 'LoginPage/handleGenerateCode exception' });
      showError({
        text: t('common.somethingWentWrong'),
      });
    } finally {
      setIsLoading(false);
    }
  }, [showError, t, guid, asymmetricKeys, generatePairingCode]);

  useEffect(() => {
    setQrCodeData(JSON.stringify({
      type: CompanionApplicationEventTypeEnum.Pair,
      CompanionApplicationId: guid,
      CompanionApplicationTypeId: CompanionApplicationTypeName.OrganizationPortal,
      OS: getOSName(),
      BrowserType: getBrowserName(),
      CompanionApplicationSymmetricKey: symmetricKey,
    }));
  }, [guid, symmetricKey]);

  useEffect(() => {
    const unsubscribe = subscribeToRealtimeAPIEvent<OrganizationPortalAuthenticated>(
      RealtimeAPIEventTypeEnum.OrganizationPortalAuthenticated,
      async (event) => {
        try {
          const { tokens } = event;
          login({ tokens, guid, symmetricKey });
        } catch (e) {
          showError({ text: t('common.somethingWentWrong') });
          logException(e, {
            message: 'LoginPage/RealtimeAPIEventTypeEnum.OrganizationPortalAuthenticated exception',
          });
        }
      },
    );
    return () => {
      unsubscribe();
    };
  }, [guid, symmetricKey, showError, t]);

  useEffect(() => {
    const unsubscribe = subscribeToRealtimeAPIEvent<OrganizationPortalAuthenticatedV2>(
      RealtimeAPIEventTypeEnum.OrganizationPortalAuthenticatedV2,
      async (event) => {
        try {
          if (!asymmetricKeys) {
            return;
          }
          const { tokens, encryptedPairingKey } = event;
          const updatedSymmetricKey = await decryptAsymmetric({
            privateKey: asymmetricKeys.privateKey,
            publicKey: asymmetricKeys.publicKey,
            cipher: encryptedPairingKey,
          });
          login({ tokens, guid, symmetricKey: updatedSymmetricKey });
        } catch (e) {
          logException(e, { message: 'LoginPage/OrganizationPortalAuthenticatedV2 exception' });
        }
      },
    );
    return () => {
      unsubscribe();
    };
  }, [guid, symmetricKey, asymmetricKeys]);

  useEffect(() => {
    const unsubscribe = subscribeToRealtimeAPIEvent(
      RealtimeAPIEventTypeEnum.OrganizationPortalAuthenticationDenied,
      () => {
        showError({
          text: t('common.authenticationDenied'),
        });
      },
    );
    return () => {
      unsubscribe();
    };
  }, [showError, t]);

  if (isAuthenticated) {
    return (
      <Navigate to={PageRouteEnum.Dashboard} />
    );
  }

  return (
    <Grid
      container
      className="min-height-100-vh"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <ScrollablePanel>
        <Grid
          container
          alignItems="center"
          direction="column"
          flexWrap="nowrap"
          rowSpacing={3}
          p={4}
          height="calc(100vh - 180px)"
          width={776}
        >
          <Grid item>
            <HeroLogoWithTextIcon height={65} width={215} />
          </Grid>
          <Grid item>
            <Typography variant="body1" align="center" color={G900}>
              {t('loginPage.scanQrCode')}
            </Typography>
          </Grid>
          <Grid item container my="auto" justifyContent="space-around">
            <Grid item xs container justifyContent="space-between">
              <Grid item />
              <Grid item xs={10} textAlign="center">
                <Grid item>
                  <QrCode
                    padding={2}
                    size={230}
                    value={qrCodeData}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" color={G600}>
                    {t('loginPage.scanQrCodeUsingUniqkeyApp')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item />
            </Grid>
            <Grid item>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item xs>
              <Grid
                container
                textAlign="center"
                className="height-100-percent"
                direction="column"
                justifyContent="space-evenly"
              >
                <Grid item>
                  <Typography variant="subtitle1" color={G900}>
                    {t('loginPage.enterCodeManually')}
                  </Typography>
                  <Box mt={3} />
                  {pairingCode ? (
                    <CopyableText
                      text={pairingCode}
                      onCopy={handleCopyToClipboard}
                      tooltipTitle={t('common.copy')}
                    />
                  ) : (
                    <Button
                      variant="outlined"
                      icon={<RefreshIcon />}
                      onClick={handleGenerateCode}
                      isLoading={isLoading}
                    >
                      {t('loginPage.generateCode')}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ScrollablePanel>
    </Grid>
  );
};

export default LoginPage;
