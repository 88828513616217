import {
  useCallback, useEffect, useMemo, useState, ReactNode,
} from 'react';
import { useQueryClient } from 'react-query';
import { useHeroSnackbar, useTranslations, usePubSub } from '@uniqkey-frontend/shared-app';
import { EmployeeTokenInfo } from '@uniqkey-backend-organization-web/api-client';
import PubSubEventEnum from '../../enums/PubSubEventEnum';
import LocalStorageKeyEnum from '../../enums/LocalStorageKeyEnum';
import UserContext from '.';
import { isSupporter } from '../../helpers/employee';
import useUserActivityChecker from '../../hooks/useUserActivityChecker';
import { useGetCurrentUser, useGetCurrentEmployee } from '../../hooks/reactQuery';
import {
  getEmployeesTokens,
  logout,
  isAuthenticated as getIsAuthenticated,
  type ILogoutParams,
} from '../../services/authService';
import { Dictionary } from '../../types/common';
import { clearContexts, logException, setUserContext } from '../../services/sentryService';
import { getActualUILanguageCode } from '../../helpers/languages';

const CURRENT_USER_REACT_QUERY_STALE_TIME = 60 * 1000;

interface IUserContextProps {
  children: ReactNode,
}

const UserProviderContext = (props: IUserContextProps) => {
  const { children } = props;
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => getIsAuthenticated());
  const [employeeTokens, setEmployeeTokens] = useState<Dictionary<EmployeeTokenInfo> | null>(
    () => getEmployeesTokens(),
  );
  const { t, currentLanguage, setLanguage } = useTranslations();
  const { showError } = useHeroSnackbar();
  const queryClient = useQueryClient();

  const handleSetEmployeeTokens = useCallback(() => {
    setEmployeeTokens(() => getEmployeesTokens());
  }, []);

  const handleLogin = useCallback(() => {
    setIsAuthenticated(true);
    handleSetEmployeeTokens();
  }, [handleSetEmployeeTokens]);

  const handleLogout = useCallback((message: string, data: Partial<ILogoutParams>) => {
    setIsAuthenticated((prevIsAuthenticated) => {
      if (!prevIsAuthenticated) {
        return false;
      }
      const { showMessage } = data ?? {};
      if (showMessage) {
        showError({ text: t('common.unauthorizedError') });
      }
      queryClient.clear();
      return false;
    });
    setEmployeeTokens(null);
  }, [showError, queryClient, t]);

  usePubSub(PubSubEventEnum.LOGIN, handleLogin);

  usePubSub(PubSubEventEnum.LOGOUT, handleLogout);

  useUserActivityChecker(isAuthenticated);

  const {
    data: currentUser,
    isLoading: isCurrentUserLoading,
    refetch: refetchCurrentUser,
  } = useGetCurrentUser({
    onSuccess: (data) => {
      if (currentLanguage === data.language) {
        return undefined;
      }
      const languageToSet = getActualUILanguageCode(data.language);
      return setLanguage(languageToSet);
    },
    onError: () => {
      logout({ showMessage: true });
    },
    enabled: isAuthenticated,
    // will keep the query fresh and won't refetch so often
    staleTime: CURRENT_USER_REACT_QUERY_STALE_TIME,
  });

  const {
    data: currentEmployee,
    isLoading: isCurrentEmployeeLoading,
    refetch: refetchCurrentEmployee,
  } = useGetCurrentEmployee({
    enabled: isAuthenticated,
    // will keep the query fresh and won't refetch so often
    staleTime: CURRENT_USER_REACT_QUERY_STALE_TIME,
  });

  const handleRefetchCurrentUser = useCallback(async () => {
    try {
      await refetchCurrentUser();
    } catch (e) {
      logException(e, {
        message: 'UserProviderContext/handleRefetchCurrentUser exception',
      });
    }
  }, [refetchCurrentUser]);

  const handleRefetchCurrentEmployee = useCallback(async () => {
    try {
      await refetchCurrentEmployee();
    } catch (e) {
      logException(e, {
        message: 'UserProviderContext/handleRefetchCurrentEmployee exception',
      });
    }
  }, [refetchCurrentEmployee]);

  usePubSub(PubSubEventEnum.TOKENS_REFRESHED, handleSetEmployeeTokens);

  useEffect(() => {
    const handler = (event: StorageEvent) => {
      if (event.key === LocalStorageKeyEnum.UserTokens) {
        const newIsAuthenticated = !!event.newValue;
        setIsAuthenticated(newIsAuthenticated);
        if (newIsAuthenticated) {
          /*
            Refetch the user if it has been changed in another tab;
            this is mostly used for the 'Support jump' page.
          */
          handleRefetchCurrentUser();
        }
      }
      if (event.key === LocalStorageKeyEnum.EmployeesTokens) {
        handleSetEmployeeTokens();
        if (getIsAuthenticated()) {
          /*
            Refetch the employee if it has been changed in another tab;
            this is mostly used for the 'Support jump' page.
          */
          handleRefetchCurrentEmployee();
        }
      }
    };
    // NOTE: add listener storage - not working on the current tab
    window.addEventListener('storage', handler);
    return () => {
      window.removeEventListener('storage', handler);
    };
  }, [handleRefetchCurrentUser, handleRefetchCurrentEmployee, handleSetEmployeeTokens]);

  useEffect(() => {
    if (!currentUser) {
      clearContexts();
      return;
    }
    setUserContext({
      id: currentUser.userId,
    });
  }, [currentUser]);

  const value = useMemo(() => ({
    currentUser: currentUser ?? null,
    currentEmployee: currentEmployee ?? null,
    isAuthenticated,
    isCurrentUserLoading: isCurrentEmployeeLoading || isCurrentUserLoading,
    employeeTokens,
    isSupporter: currentEmployee ? isSupporter(currentEmployee.employeeAccountType) : false,
  }), [
    currentUser,
    currentEmployee,
    isAuthenticated,
    isCurrentEmployeeLoading,
    isCurrentUserLoading,
    employeeTokens,
  ]);

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProviderContext;
