import { memo, useCallback } from 'react';
import {
  Typography,
  WidgetContainer,
  SecurityScoreWidget,
  G900,
  G600,
  useTranslations,
  Grid,
  Box,
} from '@uniqkey-frontend/shared-app';
import { isNumber } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useGetApplicationById } from '../../../../hooks/reactQuery';
import PageRouteEnum from '../../../../enums/PageRouteEnum';
import { ServiceModuleTabEnum } from '../ServiceModule';

interface IServiceDetailsSecurityScoreWidgetProps {
  applicationId: string;
}

const ServiceDetailsSecurityScoreWidget = (props: IServiceDetailsSecurityScoreWidgetProps) => {
  const { applicationId } = props;
  const { t } = useTranslations();
  const navigate = useNavigate();
  const {
    data: service, isLoading, isError,
  } = useGetApplicationById({ applicationId });
  const { securityScore } = service ?? {};

  const handleViewLoginsClick = useCallback(() => {
    navigate(`${PageRouteEnum.Services}/${applicationId}/${ServiceModuleTabEnum.Logins}`);
  }, [applicationId, navigate]);

  if (isError) {
    return null;
  }

  if (!isNumber(securityScore)) {
    return (
      <WidgetContainer withShadow isLoading={isLoading}>
        <Typography variant="subtitle1" color={G900}>{t('common.securityScore')}</Typography>
        <Typography variant="caption" color={G600}>
          {t('serviceDetailsSecurityScoreWidget.noSecurityScore')}
        </Typography>
      </WidgetContainer>
    );
  }

  return (
    <WidgetContainer container withShadow isLoading={isLoading} justifyContent="space-between">
      <Grid item xs={6}>
        <Typography variant="subtitle1" color={G900}>{t('common.securityScore')}</Typography>
        <Typography variant="body1" color={G600}>
          {t('serviceDetailsSecurityScoreWidget.calculatedBased')}
        </Typography>
        <Box mt={2} />
        <Typography
          asLink
          variant="subtitle1"
          onClick={handleViewLoginsClick}
        >
          {t('serviceDetailsSecurityScoreWidget.viewLogins')}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Box pl={2} pb={2} pr={4} pt={4} display="flex" justifyContent="center">
          <SecurityScoreWidget percentage={Math.floor(securityScore)} t={t} />
        </Box>
      </Grid>
    </WidgetContainer>
  );
};

export default memo(ServiceDetailsSecurityScoreWidget);
