import { useMemo, memo } from 'react';
import {
  Table,
  useTranslations,
  IUseTableReturn,
  IUseQueryTableFetchReturn,
  TypographyWithTooltip,
  IMaterialTableOptions,
  IUseTableLocalizationReturn,
  formatDate,
  DateTimeFormatEnum,
  S600,
  G600,
  E600,
} from '@uniqkey-frontend/shared-app';
import {
  GetPartnerSupportRequestsResponseModel,
  PartnerSupportRequestStatusName,
} from '@uniqkey-backend-organization-web/api-client';
import { PARTNER_ACCESS_REQUESTS_TRANSLATION_KEYS } from '../../../constants';
import {
  TGetPartnerAccessRequestsMethod,
} from '../../../hooks/usePartnerSupportRequestsAPI/interfaces';

export interface IPartnerAccessRequestsTableRow extends GetPartnerSupportRequestsResponseModel {
  processedDate: string | null;
}

interface IPartnerAccessRequestsTableProps extends
  Omit<IUseTableReturn<IPartnerAccessRequestsTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Omit<
    IUseQueryTableFetchReturn<TGetPartnerAccessRequestsMethod>,
    'data' | 'resetQuery' | 'isFetchedAfterMount'
  >
{
  partnerAccessRequests: IUseQueryTableFetchReturn<TGetPartnerAccessRequestsMethod>['data'];
  selectedPartnerAccessRequests: IUseTableReturn<IPartnerAccessRequestsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

const TABLE_OPTIONS: IMaterialTableOptions<IPartnerAccessRequestsTableRow> = {
  selection: true,
};

const PARTNER_ACCESS_REQUESTS_STATUS_COLORS = {
  [PartnerSupportRequestStatusName.Approved]: S600,
  [PartnerSupportRequestStatusName.Pending]: G600,
  [PartnerSupportRequestStatusName.Rejected]: E600,
  [PartnerSupportRequestStatusName.Canceled]: G600,
};

const PartnerAccessRequestsTable = (props: IPartnerAccessRequestsTableProps) => {
  const {
    partnerAccessRequests: prePartnerAccessRequests,
    isLoading,
    total,
    localization,
    activePage,
    setActivePage,
    perPage,
    onPerPageChange,
    columnOrderBy,
    onColumnOrderByChange,
    columnOrderDirection,
    onColumnOrderDirectionChange,
    selectedPartnerAccessRequests,
    onRowSelectionChange,
    createColumns,
  } = props;

  const { t } = useTranslations();

  const partnerAccessRequests = useMemo(() => prePartnerAccessRequests.map((request) => ({
    ...request,
    processedDate: formatDate({ date: request.createdAt }, DateTimeFormatEnum.FULL),
  })), [prePartnerAccessRequests]);

  const columns = useMemo(() => createColumns([
    {
      title: t('requestsPage.partnerAccessRequestsTab.email'),
      field: 'partnerUserEmail',
      width: '27%',
      render: (rowData) => (
        <TypographyWithTooltip variant="body1">
          {rowData.partnerUserEmail}
        </TypographyWithTooltip>
      ),
      sorting: false,
    },
    {
      title: t('requestsPage.partnerAccessRequestsTab.partnerName'),
      field: 'partnerName',
      width: '23%',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.partnerName}
        </TypographyWithTooltip>
      ),
      sorting: false,
    },
    {
      title: t('requestsPage.partnerAccessRequestsTab.partnerUserName'),
      field: 'partnerUserName',
      width: '22%',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.partnerUserName}
        </TypographyWithTooltip>
      ),
      sorting: false,
    },
    {
      title: t('requestsPage.partnerAccessRequestsTab.requestDate'),
      field: 'createdAt',
      width: '18%',
      render: (rowData) => (
        <TypographyWithTooltip variant="body1">
          {rowData.processedDate}
        </TypographyWithTooltip>
      ),
      sorting: false,
    },
    {
      title: t('requestsPage.partnerAccessRequestsTab.status'),
      field: 'requestStatus',
      width: '10%',
      render: (rowData) => (
        <TypographyWithTooltip
          variant="body1"
          color={PARTNER_ACCESS_REQUESTS_STATUS_COLORS[rowData.requestStatus]}
        >
          {t(PARTNER_ACCESS_REQUESTS_TRANSLATION_KEYS[rowData.requestStatus])}
        </TypographyWithTooltip>
      ),
      sorting: false,
    },
  ]), [createColumns, t]);

  return (
    <Table
      t={t}
      totalItems={total}
      columns={columns}
      data={partnerAccessRequests}
      isLoading={isLoading}
      options={TABLE_OPTIONS}
      activePage={activePage}
      onActivePageChange={setActivePage}
      perPage={perPage}
      onPerPageChange={onPerPageChange}
      columnOrderBy={columnOrderBy}
      onColumnOrderByChange={onColumnOrderByChange}
      columnOrderDirection={columnOrderDirection}
      onColumnOrderDirectionChange={onColumnOrderDirectionChange}
      selectedRows={selectedPartnerAccessRequests}
      localization={localization}
      onSelectionChange={onRowSelectionChange}
      rowSelectByKey="partnerSupportRequestId"
      rowHeight={51}
    />
  );
};

export default memo(PartnerAccessRequestsTable);
