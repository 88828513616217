import {
  ForwardedRef, useEffect, useRef,
} from 'react';
import type {
  ChartDataset, ParsingOptions, ChartData, Chart,
} from 'chart.js';
import { S600 } from '@uniqkey-frontend/shared-app';
import { createLineChartGradient } from '../../helpers/ChartJSLib';
import type { IChartDataPoint, TLineChartType } from '../../helpers/ChartJSLib/interfaces';

interface IUseLineChartParams {
  data: ChartDataset<TLineChartType, IChartDataPoint[]>['data'];
  parsing: ParsingOptions['parsing'];
  withGradient?: boolean;
}

interface IUseLineChartReturn {
  initialChartData: ChartData<TLineChartType, IChartDataPoint[]>;
  chartRef: ForwardedRef<Chart<TLineChartType, IChartDataPoint[]> | undefined>;
}

const useLineChart = (params: IUseLineChartParams): IUseLineChartReturn => {
  const { data, parsing, withGradient = true } = params;

  const initialChartData = useRef<IUseLineChartReturn['initialChartData']>({
    datasets: [{
      data,
      parsing,
    }],
  });

  const chartRef: IUseLineChartReturn['chartRef'] = useRef(undefined);

  useEffect(() => {
    const chart = chartRef.current;
    if (!chart) {
      return;
    }
    const updatedDataset: ChartDataset<TLineChartType, IChartDataPoint[]> = {
      data,
      parsing,
    };
    updatedDataset.pointBackgroundColor = S600;
    updatedDataset.pointBorderColor = S600;
    updatedDataset.pointRadius = 4.5;
    updatedDataset.pointHoverBackgroundColor = S600;
    updatedDataset.pointHoverBorderColor = S600;
    if (withGradient) {
      updatedDataset.backgroundColor = createLineChartGradient(chart.ctx, chart.chartArea);
      updatedDataset.borderColor = S600;
      updatedDataset.fill = 'start';
    }
    chart.data.datasets = [updatedDataset];
    chart.update();
  }, [data, parsing, withGradient]);

  return { initialChartData: initialChartData.current, chartRef };
};

export default useLineChart;
