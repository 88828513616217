import { memo } from 'react';
import {
  S600,
  E600,
  ArrowDownIcon,
  ArrowUpIcon,
  Divider,
  Grid,
  G600,
  G900,
  Typography,
  TypographyWithHelp,
  usePasswordStrength,
  useTranslations,
} from '@uniqkey-frontend/shared-app';

interface IUniqkeyAccessScoreStatisticsWidgetProps {
  masterPasswordStrength: number;
  isMasterPasswordReused?: boolean;
  hasReusedPasswords?: boolean;
}

const TITLE_TYPOGRAPHY_PROPS = { color: G900 };
const DIVIDER_SX = { marginX: -3 };

const UniqkeyAccessScoreStatisticsWidget = (props: IUniqkeyAccessScoreStatisticsWidgetProps) => {
  const {
    masterPasswordStrength, isMasterPasswordReused, hasReusedPasswords,
  } = props;
  const { t } = useTranslations();
  const { color } = usePasswordStrength({ strength: masterPasswordStrength });

  return (
    <>
      <Grid item>
        <Divider sx={DIVIDER_SX} />
      </Grid>
      <Grid item container justifyContent="space-between" py={1.5}>
        <Grid item>
          <Typography variant="caption" color={G600}>
            {t('uniqkeyAccessScoreStatisticsWidget.masterPasswordStrength')}
            :&nbsp;
            <Typography variant="caption" component="span" color={color}>
              {masterPasswordStrength}
              %
            </Typography>
          </Typography>
        </Grid>
        <Grid item>
          <TypographyWithHelp
            TypographyProps={TITLE_TYPOGRAPHY_PROPS}
            helperText={t('uniqkeyAccessScoreStatisticsWidget.masterPasswordStrength.tooltip')}
          >
            {masterPasswordStrength ? (
              <>
                <ArrowUpIcon color={S600} />
                <Typography color={S600} component="span">+</Typography>
                <Typography variant="caption" color={G600}>
                  {masterPasswordStrength}
                  %
                </Typography>
              </>
            ) : (
              <Typography variant="caption" color={G600}>0%</Typography>
            )}
          </TypographyWithHelp>
        </Grid>
      </Grid>
      <Grid item>
        <Divider sx={DIVIDER_SX} />
      </Grid>
      <Grid item container justifyContent="space-between" py={1.5}>
        <Grid item>
          <Typography variant="caption" color={G600}>
            {t('uniqkeyAccessScoreStatisticsWidget.masterPasswordIsReused')}
            :&nbsp;
            <Typography
              variant="caption"
              component="span"
              color={isMasterPasswordReused ? E600 : S600}
            >
              {t(isMasterPasswordReused ? 'common.yes' : 'common.no')}
            </Typography>
          </Typography>
        </Grid>
        <Grid item>
          <TypographyWithHelp
            TypographyProps={TITLE_TYPOGRAPHY_PROPS}
            helperText={t('uniqkeyAccessScoreStatisticsWidget.masterPasswordIsReused.tooltip')}
          >
            {isMasterPasswordReused && (
              <>
                <ArrowDownIcon color={E600} />
                <Typography color={E600} component="span">-</Typography>
                <Typography variant="caption" color={G600}>100%</Typography>
              </>
            )}
          </TypographyWithHelp>
        </Grid>
      </Grid>
      <Grid item>
        <Divider sx={DIVIDER_SX} />
      </Grid>
      <Grid item container justifyContent="space-between" pt={1.5}>
        <Grid item>
          <Typography variant="caption" color={G600}>
            {t('uniqkeyAccessScoreStatisticsWidget.hasReusedPasswords')}
            :&nbsp;
            <Typography
              variant="caption"
              component="span"
              color={hasReusedPasswords ? E600 : S600}
            >
              {t(hasReusedPasswords ? 'common.yes' : 'common.no')}
            </Typography>
          </Typography>
        </Grid>
        <Grid item>
          <TypographyWithHelp
            TypographyProps={TITLE_TYPOGRAPHY_PROPS}
            helperText={t('uniqkeyAccessScoreStatisticsWidget.hasReusedPasswords.tooltip')}
          >
            {hasReusedPasswords && (
              <>
                <ArrowDownIcon color={E600} />
                <Typography color={E600} component="span">-</Typography>
                <Typography variant="caption" color={G600}>40%</Typography>
              </>
            )}
          </TypographyWithHelp>
        </Grid>
      </Grid>
    </>
  );
};

export default memo(UniqkeyAccessScoreStatisticsWidget);
