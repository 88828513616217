import {
  WidgetContainer,
  Grid,
  Typography,
  Divider,
  G900,
  G600,
  useTranslations,
  Button,
  INumericTextFieldProps,
  noLeadingZeroValidator,
  minMaxValueValidator,
  useHeroSnackbar,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import { useCallback, memo } from 'react';
import NumericTextFieldWithDefaultHelperText from '../NumericTextFieldWithDefaultHelperText';
import { IGeneralRetentionPeriodWidgetProps } from '../GeneralRetentionPeriodWidget';
import { FIVE_YEARS } from '../../../../../../constants';
import { useUpdateGeneralRetentionPeriod } from '../../../../../../hooks/reactQuery';
import { logException } from '../../../../../../services/sentryService';
import PubSubEventEnum from '../../../../../../enums/PubSubEventEnum';

interface IUpdateGeneralRetentionPeriodFormValue {
  retentionPeriodInDays: string;
}

interface IGeneralRetentionPeriodWidgetContainerProps extends Pick<
  IGeneralRetentionPeriodWidgetProps, 'retentionPeriodInDays'
> {}

const GeneralRetentionPeriodWidgetContainer = (
  props: IGeneralRetentionPeriodWidgetContainerProps,
) => {
  const { retentionPeriodInDays } = props;
  const { t } = useTranslations();
  const { showError, showSuccess } = useHeroSnackbar();

  const {
    mutate: updateGeneralRetentionPeriod,
    isLoading: isGeneralRetentionPeriodUpdating,
  } = useUpdateGeneralRetentionPeriod({ useOptimisticUpdates: true });

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<IUpdateGeneralRetentionPeriodFormValue>({
    mode: 'all',
    defaultValues: {
      retentionPeriodInDays: retentionPeriodInDays?.toString() ?? '',
    },
  });

  const [watchRetentionPeriodInDays] = watch(['retentionPeriodInDays']);

  const handleGeneralRetentionPeriodInDaysChange = useCallback<
    NonNullable<INumericTextFieldProps['onChange']>
  >((event) => {
    setValue('retentionPeriodInDays', event.target.value, { shouldDirty: true });
  }, [setValue]);

  const handleUpdateGeneralRetentionPeriod = useCallback((
    formValue: IUpdateGeneralRetentionPeriodFormValue,
  ): void => {
    const {
      retentionPeriodInDays: preRetentionPeriodInDays,
    } = formValue;

    const parsedRetentionPeriodInDays = parseInt(preRetentionPeriodInDays, 10);

    updateGeneralRetentionPeriod(
      { retentionPeriodInDays: parsedRetentionPeriodInDays },
      {
        onError: (e) => {
          showError({ text: t('common.somethingWentWrong') });
          logException(e, {
            // eslint-disable-next-line max-len
            message: 'GeneralRetentionPeriodWidgetContainer/handleUpdateGeneralRetentionPeriod exception',
          });
        },
        onSuccess: () => {
          showSuccess({
            text: t('settingsPage.retentionPeriodTab.generalRetentionPeriodWidget.success.message'),
          });
          reset({ retentionPeriodInDays: preRetentionPeriodInDays });
          PubSub.publish(PubSubEventEnum.RETENTION_PERIOD_CHANGED);
        },
      },
    );
  }, [reset, showError, showSuccess, t, updateGeneralRetentionPeriod]);

  const disabled = !isDirty || !!errors.retentionPeriodInDays;

  return (
    <WidgetContainer container withShadow p={3}>
      <Grid container flexDirection="column">
        <form onSubmit={handleSubmit(handleUpdateGeneralRetentionPeriod)} autoComplete="off">
          <Grid item mb={0.5}>
            <Typography variant="body3" color={G900}>
              {t('settingsPage.retentionPeriodTab.generalRetentionPeriodWidget.title')}
            </Typography>
          </Grid>
          <Divider />
          <Grid item mt={0.5}>
            <Typography variant="body2" color={G600}>
              {t('settingsPage.retentionPeriodTab.generalRetentionPeriodWidget.description')}
            </Typography>
          </Grid>
          <Grid item container flexDirection="column" mt={0.5} rowGap={2}>
            <Grid container gap={1}>
              {/* Same padding as on TextField to center text */}
              <Grid item pt="12px" width={140}>
                <Typography>
                  {/* eslint-disable-next-line max-len */}
                  {t('settingsPage.retentionPeriodTab.generalRetentionPeriodWidget.retentionPeriod.title')}
                </Typography>
              </Grid>
              <NumericTextFieldWithDefaultHelperText
                allowLeadingZeros
                name="retentionPeriodInDays"
                error={!!errors.retentionPeriodInDays}
                helperText={errors.retentionPeriodInDays?.message}
                // eslint-disable-next-line max-len
                placeholder={t('settingsPage.retentionPeriodTab.textField.placeholder')}
                value={watchRetentionPeriodInDays}
                onChange={handleGeneralRetentionPeriodInDaysChange}
                inputProps={{
                  ...register('retentionPeriodInDays', {
                    required: t('validation.required'),
                    validate: {
                      noLeadingZero: (value) => noLeadingZeroValidator(value)
                        || t('validation.noLeadingZero'),
                      minMaxValue: (value) => minMaxValueValidator({
                        value,
                        minValue: 30,
                        maxValue: FIVE_YEARS,
                        // eslint-disable-next-line max-len
                      }) || t('settingsPage.retentionPeriodTab.generalRetentionPeriodWidget.textField.validation.atLeast30Days'),
                    },
                  }),
                }}
                showDefaultHelperText={!errors.retentionPeriodInDays}
                defaultHelperText={t(
                  'settingsPage.retentionPeriodTab.generalRetentionPeriodWidget.textField.info',
                )}
              />
            </Grid>
          </Grid>
          <Grid container item mt={3} justifyContent="flex-end">
            <Button
              variant="outlined"
              type="submit"
              isLoading={isGeneralRetentionPeriodUpdating}
              disabled={disabled}
            >
              {isDirty ? t('common.apply') : t('common.change')}
            </Button>
          </Grid>
        </form>
      </Grid>
    </WidgetContainer>
  );
};

export default memo(GeneralRetentionPeriodWidgetContainer);
