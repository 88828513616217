import { memo, useMemo } from 'react';
import {
  EntityDetailsContainer,
  Grid,
  G900,
  Typography,
  useTranslations,
  formatDate,
  TypographyWithTooltip,
  LoginAvatar,
} from '@uniqkey-frontend/shared-app';
import { useGetApplicationById } from '../../../../hooks/reactQuery';

interface IServiceDetailsWidgetProps {
  applicationId: string;
}

const ServiceDetailsWidget = (props: IServiceDetailsWidgetProps) => {
  const { applicationId } = props;
  const { t } = useTranslations();
  const {
    data: service, isLoading, isError,
  } = useGetApplicationById({ applicationId });

  const {
    name, activityAt, iconUrl,
  } = service ?? {};
  const lastActivity = useMemo(() => formatDate({ date: activityAt }), [activityAt]);

  if (isError) {
    return null;
  }

  return (
    <EntityDetailsContainer container isLoading={isLoading}>
      <Grid container alignItems="center" mb={4}>
        <LoginAvatar src={iconUrl} />
        <TypographyWithTooltip variant="h6">
          {name}
        </TypographyWithTooltip>
      </Grid>
      <Grid container flexDirection="column" justifyContent="end">
        <Typography color={G900} textAlign="end">
          {t('employeeDetailsWidget.lastActivity')}
        </Typography>
        <TypographyWithTooltip variant="subtitle1" color={G900} textAlign="end">
          {lastActivity}
        </TypographyWithTooltip>
      </Grid>
    </EntityDetailsContainer>
  );
};

export default memo(ServiceDetailsWidget);
