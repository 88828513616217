import {
  WidgetContainer, Grid, Typography, Divider, G900, G600, useTranslations,
} from '@uniqkey-frontend/shared-app';

const RetentionPeriodWidget = () => {
  const { t } = useTranslations();
  return (
    <WidgetContainer container withShadow p={3}>
      <Grid container flexDirection="column">
        <Grid item mb={0.5}>
          <Typography variant="body3" color={G900}>
            {t('settingsPage.retentionPeriodTab.retentionPeriodWidget.title')}
          </Typography>
        </Grid>
        <Divider />
        <Grid item mt={0.5}>
          <Typography variant="body2" color={G600}>
            {t('settingsPage.retentionPeriodTab.retentionPeriodWidget.description')}
          </Typography>
        </Grid>
      </Grid>
    </WidgetContainer>
  );
};

export default RetentionPeriodWidget;
