import { BaseEitherError } from '@uniqkey-frontend/shared-app';
import {
  TOnboardingPostMessageWithTimeoutOperationErrors,
} from '../postMessageWithTimeoutOperation/errors';
import {
  TOnboardingSendMessageWithTimeoutOperationErrors,
} from '../sendMessageWithTimeoutOperation/errors';

export class OnboardingTransferDataUnhandledError extends BaseEitherError {}

export class OnboardingTransferDataUnknownError extends BaseEitherError {}

export class OnboardingTransferDataError extends BaseEitherError {}

export class OnboardingTransferDataMalformedResponseError extends BaseEitherError {}

export type TOnboardingTransferDataOperationErrors =
  | TOnboardingSendMessageWithTimeoutOperationErrors
  | TOnboardingPostMessageWithTimeoutOperationErrors
  | OnboardingTransferDataUnhandledError
  | OnboardingTransferDataUnknownError
  | OnboardingTransferDataError
  | OnboardingTransferDataMalformedResponseError;
