import {
  Ownership,
  EmployeeAccountStatus,
  EmployeeAccountType,
  ActivityFilter,
  Password2FaStatus,
  PartnerSupportRequestStatusName,
} from '@uniqkey-backend-organization-web/api-client';
import {
  S600, E600, G500, G600, W500,
} from '@uniqkey-frontend/shared-app';

export const TWO_FA_TRANSLATION_KEYS = {
  On: 'common.on',
  Off: 'common.off',
  Na: 'common.notAvailableShort',
};

export const OWNERSHIP_TRANSLATION_KEYS = {
  [Ownership.Employees]: 'ownership.employees',
  [Ownership.Groups]: 'ownership.groups',
  [Ownership.Unmanaged]: 'ownership.unmanaged',
};

export const ACTIVITY_FILTER_TRANSLATION_KEYS = {
  [ActivityFilter.Active]: 'activityFilter.active',
  [ActivityFilter.NotActive]: 'activityFilter.notActive',
};

export const EMPLOYEE_ACCOUNT_STATUS_TRANSLATION_KEYS = {
  [EmployeeAccountStatus.Active]: 'employeeStatuses.active',
  [EmployeeAccountStatus.Invited]: 'employeeStatuses.invited',
  [EmployeeAccountStatus.Archived]: 'employeeStatuses.archived',
  [EmployeeAccountStatus.Unprocessed]: 'employeeStatuses.pendingApproval',
  [EmployeeAccountStatus.Staged]: 'employeeStatuses.staged',
  [EmployeeAccountStatus.MigrationInvited]: 'employeeStatuses.migrationInvited',
  [EmployeeAccountStatus.Migrated]: 'employeeStatuses.migrated',
  [EmployeeAccountStatus.ExistingUnprocessed]: 'employeeStatuses.existingUnprocessed',
};

export const EMPLOYEE_ACCOUNT_TYPE_TRANSLATION_KEYS = {
  [EmployeeAccountType.Admin]: 'adminRights.admin',
  [EmployeeAccountType.KeylessAdmin]: 'adminRights.promotedToAdmin',
  [EmployeeAccountType.Employee]: 'adminRights.notAdmin',
  [EmployeeAccountType.Supporter]: 'adminRights.notAdmin',
};

export const SCIM_TRANSLATION_KEYS = {
  true: 'scim.scim',
  false: 'scim.no',
};

export const EMPLOYEE_ACCOUNT_STATUS_COLORS = {
  [EmployeeAccountStatus.Active]: S600,
  [EmployeeAccountStatus.Invited]: G600,
  [EmployeeAccountStatus.Archived]: E600,
  [EmployeeAccountStatus.Unprocessed]: G500,
  [EmployeeAccountStatus.Staged]: G600,
  [EmployeeAccountStatus.MigrationInvited]: G600,
  [EmployeeAccountStatus.Migrated]: G600,
  [EmployeeAccountStatus.ExistingUnprocessed]: G500,
};

export const OWNERSHIP_COLORS = {
  [Ownership.Employees]: S600,
  [Ownership.Groups]: S600,
  [Ownership.Unmanaged]: G500,
};

export const SCIM_COLORS = {
  true: W500,
  false: G600,
};

export const TWO_FA_COLORS = {
  On: S600,
  Off: E600,
  Na: G600,
};

export const TWO_FA_FILTER_TRANSLATION_KEYS = {
  [Password2FaStatus.On]: 'common.on',
  [Password2FaStatus.Off]: 'common.off',
  [Password2FaStatus.Na]: 'common.notAvailable',
};

export const PARTNER_ACCESS_REQUESTS_TRANSLATION_KEYS = {
  [PartnerSupportRequestStatusName.Approved]: 'partnerAccessRequestStatuses.approved',
  [PartnerSupportRequestStatusName.Pending]: 'partnerAccessRequestStatuses.pending',
  [PartnerSupportRequestStatusName.Rejected]: 'partnerAccessRequestStatuses.rejected',
  [PartnerSupportRequestStatusName.Canceled]: 'partnerAccessRequestStatuses.canceled',
};

export const UNIQKEY_FINDER_IGNORE_CLASSNAME_PROP = { className: 'uniqkey-finder-ignore' };

export const EMPLOYEE_IS_NOT_ACTIVE_ERROR = 'Employee for this vault is not active';

export const FIVE_YEARS = 365 * 5;
