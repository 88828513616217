import {
  Dispatch, SetStateAction, useCallback, useState,
} from 'react';
import { upperFirst } from 'lodash';
import {
  useHeroSnackbar,
  useTable,
  useQueryTableFetch,
  useTranslations,
  useTableLocalization,
  useFilterButton,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  usePubSub,
  buildTablePreserverDecoderConfigItem,
  TablePreserverCommonInitialTableValues,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  IUseFilterButtonReturn,
  TUseTablePreserverDecoderConfigs,
  TablePreserverDecoderConfigItemTypeEnum,
  TSetSearchParams,
  TSecurityScoreName,
  getSecurityScoreServicesAndEmployeesStrength,
} from '@uniqkey-frontend/shared-app';
import {
  ApplicationOrderPropertyName,
} from '@uniqkey-backend-organization-web/api-client';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import {
  IGetServicesListParams,
  TGetServicesListMethod,
} from '../../useApplicationsAPI/interfaces';
import { IServicesTableRow } from '../../../components/tables/ServicesTable';
import {
  IServicesListFilterSubmitResult,
} from '../../../pages/ServicesPage/components/ServicesListFilter';
import useApplicationsAPI from '../../useApplicationsAPI';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';

export const REACT_QUERY_SERVICES_KEY = [ReactQueryKeyEnum.Services];

export interface IUseServicesTableParams {
  noDataMessageKey: string;
}

export interface IUseServicesTableReturn extends
  Omit<IUseTableReturn<IServicesTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage'>,
  Omit<IUseQueryTableFetchReturn<TGetServicesListMethod>, 'data' | 'isFetchedAfterMount'>,
  IUseFilterButtonReturn
{
  services: IUseQueryTableFetchReturn<TGetServicesListMethod>['data'];
  selectedServices: IUseTableReturn<IServicesTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
  searchQuery: IGetServicesListParams['searchQuery'];
  setSearchQuery: Dispatch<SetStateAction<IGetServicesListParams['searchQuery']>>;
  filterValues: IServicesListFilterSubmitResult;
  setFilterValues: Dispatch<SetStateAction<IServicesListFilterSubmitResult>>;
}

interface ITablePreservationConfigs extends
  Omit<IGetServicesListParams,
    'page' | 'pageLength' | 'minSecurityScore' | 'maxSecurityScore' | 'orderPropertyName'
    | 'isSecurityScoreEmpty' | 'isDescending'
  >,
  Pick<IUseTableReturn<IServicesTableRow>, 'columnOrderBy' | 'columnOrderDirection'>
{
  activePage: IGetServicesListParams['page'];
  perPage: IGetServicesListParams['pageLength'];
  securityScoreName: TSecurityScoreName;
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
  searchQuery: true,
  columnOrderBy: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: ApplicationOrderPropertyName },
  ),
  columnOrderDirection: true,
  securityScoreName: true,
};

const useServicesTable = (
  params: IUseServicesTableParams,
): IUseServicesTableReturn => {
  const {
    noDataMessageKey,
  } = params;

  const queryClient = useQueryClient();
  const { t } = useTranslations();
  const { showError } = useHeroSnackbar();
  const { getServicesList } = useApplicationsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
    searchQuery: initialSearchQuery,
    columnOrderBy: initialColumnOrderBy,
    columnOrderDirection: initialColumnOrderDirection,
    securityScoreName: initialSecurityScoreName,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const [searchQuery, setSearchQuery] = useState<
    IGetServicesListParams['searchQuery']
  >(() => initialSearchQuery);
  const [filterValues, setFilterValues] = useState<
    IServicesListFilterSubmitResult
  >({
    securityScoreName: initialSecurityScoreName,
  });

  const { isFilterActive, numberOfActiveFilters } = useFilterButton(filterValues);

  const {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedRows: selectedServices,
    resetTableMethods,
    ...restTableProps
  } = useTable<IServicesTableRow>({
    initialActivePage,
    initialPerPage,
    initialColumnOrderBy,
    initialColumnOrderDirection,
  });

  const {
    resetTable,
    resetActivePage,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
    setSearchQuery(TablePreserverCommonInitialTableValues.searchQuery);
    setFilterValues({
      securityScoreName: TablePreserverCommonInitialTableValues.securityScoreName,
    });
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: services, isLoading, isFetchedAfterMount, total, resetQuery,
  } = useQueryTableFetch({
    queryKey: REACT_QUERY_SERVICES_KEY,
    queryClient,
    request: getServicesList,
    params: {
      page: activePage,
      pageLength: perPage,
      ...getSecurityScoreServicesAndEmployeesStrength(filterValues.securityScoreName),
      searchQuery,
      orderPropertyName: ApplicationOrderPropertyName[
        upperFirst(columnOrderBy) as keyof typeof ApplicationOrderPropertyName
      ],
      isDescending: columnOrderDirection ? columnOrderDirection === 'desc' : undefined,
    },
    onRequestError: handleOnRequestError,
  });

  const { localization } = useTableLocalization({
    searchQuery,
    isFilterActive,
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
    searchQuery,
    columnOrderBy,
    columnOrderDirection,
    securityScoreName: filterValues.securityScoreName,
  });

  return {
    services,
    isLoading,
    total,
    resetQuery,
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedServices,
    localization,
    searchQuery,
    setSearchQuery,
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    resetActivePage,
    ...restTableProps,
  };
};

export default useServicesTable;
