import {
  BrowserRouter, Navigate, Route, Routes,
} from 'react-router-dom';
import { useHeroSnackbar } from '@uniqkey-frontend/shared-app';
import { useUser } from './contexts/UserContext';
import MigrationPageRouteEnum from './enums/MigrationPageRouteEnum';
import ProtectedRoute from './components/ProtectedRoute';
import LoginPage from './pages/LoginPage';
import OrganizationsPage from './pages/OrganizationsPage';
import ReportPage from './pages/ReportPage';
import CongratsPage from './pages/CongratsPage';
import UnauthorizedLayout from '../layouts/UnauthorizedLayout';
import MainPageRouteEnum from '../enums/PageRouteEnum';
import MainLoginPage from '../pages/LoginPage';

const Router = () => {
  const { getSnackbar } = useHeroSnackbar();
  const { isAuthenticated } = useUser();

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={MigrationPageRouteEnum.Migration}
          element={<LoginPage />}
        />
        <Route element={<ProtectedRoute isAuthorized={isAuthenticated} />}>
          <Route
            path={MigrationPageRouteEnum.Organizations}
            element={<OrganizationsPage />}
          />
          <Route
            path={MigrationPageRouteEnum.Report}
            element={<ReportPage />}
          />
          <Route
            path={MigrationPageRouteEnum.Congrats}
            element={<CongratsPage />}
          />
        </Route>
        <Route
          path={MainPageRouteEnum.Login}
          element={(
            <UnauthorizedLayout>
              <MainLoginPage />
            </UnauthorizedLayout>
          )}
        />
        <Route
          path="*"
          element={<Navigate to={MigrationPageRouteEnum.Migration} />}
        />
      </Routes>
      {getSnackbar()}
    </BrowserRouter>
  );
};

export default Router;
