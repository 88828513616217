import { useCallback } from 'react';
import { upperFirst } from 'lodash';
import {
  useHeroSnackbar,
  useTable,
  useQueryTableFetch,
  useTranslations,
  useTableLocalization,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  usePubSub,
  buildTablePreserverDecoderConfigItem,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  TUseTablePreserverDecoderConfigs,
  TablePreserverDecoderConfigItemTypeEnum,
  TSetSearchParams,
} from '@uniqkey-frontend/shared-app';
import {
  GetPartnerUsersAccessibleToCurrentOrganizationOrderProperty,
} from '@uniqkey-backend-organization-web/api-client';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import {
  IGetPartnerUsersAccessParams,
  TGetPartnerUsersAccessMethod,
} from '../../useOrganizationSettingsAPI/interfaces';
import useOrganizationSettingsAPI from '../../useOrganizationSettingsAPI';
import { IPartnerUsersWithAccessTableRow }
  from '../../../components/tables/PartnerUsersWithAccessTable';

export const REACT_QUERY_PARTNER_USERS_WITH_ACCESS_KEY = [ReactQueryKeyEnum.PartnerUsersWithAccess];

export interface IUsePartnerUsersWithAccessTableParams {
  noDataMessageKey: string;
}

export interface IUsePartnerUsersWithAccessTableReturn extends
  Omit<IUseTableReturn<IPartnerUsersWithAccessTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage' | 'resetSelectedRows'>,
  Omit<IUseQueryTableFetchReturn<TGetPartnerUsersAccessMethod>, 'data' | 'isFetchedAfterMount'>
{
  partnerUsers: IUseQueryTableFetchReturn<TGetPartnerUsersAccessMethod>['data'];
  selectedPartnerUsers: IUseTableReturn<IPartnerUsersWithAccessTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

interface ITablePreservationConfigs extends
  Omit<IGetPartnerUsersAccessParams,
    'page' | 'pageLength' | 'searchQuery' | 'orderPropertyName' | 'isDescending'
  >,
  Pick<IUseTableReturn<IPartnerUsersWithAccessTableRow>, 'columnOrderBy' | 'columnOrderDirection'>
{
  activePage: IGetPartnerUsersAccessParams['page'];
  perPage: IGetPartnerUsersAccessParams['pageLength'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
  columnOrderBy: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: GetPartnerUsersAccessibleToCurrentOrganizationOrderProperty },
  ),
  columnOrderDirection: true,
};

const usePartnerUsersWithAccessTable = (
  params: IUsePartnerUsersWithAccessTableParams,
): IUsePartnerUsersWithAccessTableReturn => {
  const {
    noDataMessageKey,
  } = params;

  const queryClient = useQueryClient();
  const { t } = useTranslations();
  const { showError } = useHeroSnackbar();
  const { getPartnerUsersAccess } = useOrganizationSettingsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
    columnOrderBy: initialColumnOrderBy,
    columnOrderDirection: initialColumnOrderDirection,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedRows: selectedPartnerUsers,
    resetTableMethods,
    ...restTableProps
  } = useTable<IPartnerUsersWithAccessTableRow>({
    initialActivePage,
    initialPerPage,
    initialColumnOrderBy,
    initialColumnOrderDirection,
  });

  const {
    resetTable,
    resetActivePage,
    resetSelectedRows,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: partnerUsers, isLoading, isFetchedAfterMount, total, resetQuery,
  } = useQueryTableFetch({
    queryKey: REACT_QUERY_PARTNER_USERS_WITH_ACCESS_KEY,
    queryClient,
    request: getPartnerUsersAccess,
    params: {
      page: activePage,
      pageLength: perPage,
      orderPropertyName: GetPartnerUsersAccessibleToCurrentOrganizationOrderProperty[
        upperFirst(
          columnOrderBy,
        ) as keyof typeof GetPartnerUsersAccessibleToCurrentOrganizationOrderProperty
      ],
      isDescending: columnOrderDirection ? columnOrderDirection === 'desc' : undefined,
    },
    onRequestError: handleOnRequestError,
    autoRefetch: true,
  });

  const { localization } = useTableLocalization({
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
  });

  return {
    partnerUsers,
    isLoading,
    total,
    resetQuery,
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedPartnerUsers,
    localization,
    resetActivePage,
    resetSelectedRows,
    ...restTableProps,
  };
};

export default usePartnerUsersWithAccessTable;
