import { useMemo, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BreadcrumbsContainer,
  Breadcrumbs,
  useHeroSnackbar,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import PageRouteEnum from '../../../../enums/PageRouteEnum';
import { useGetVaultPasswordById } from '../../../../hooks/reactQuery';

interface IOrganizationLoginPageBreadcrumbsProps {
  vaultId: string;
}
const OrganizationLoginPageBreadcrumbs = (props: IOrganizationLoginPageBreadcrumbsProps) => {
  const { vaultId } = props;
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { showError } = useHeroSnackbar();

  const { data: vault, isLoading } = useGetVaultPasswordById(
    { vaultId },
    {
      onError: () => {
        showError({ text: t('common.somethingWentWrong') });
        navigate(PageRouteEnum.AllSecuredData);
      },
    },
  );

  const breadcrumbsNodes = useMemo(() => {
    if (isLoading || !vault) {
      return [];
    }
    return [
      {
        title: t('navigation.allLogins'),
        onClick: () => navigate(PageRouteEnum.AllSecuredData),
      },
      { title: t('navigation.logins'), onClick: () => navigate(PageRouteEnum.AllSecuredData) },
      { title: vault.name },
    ];
  }, [isLoading, vault, navigate, t]);

  return (
    <BreadcrumbsContainer>
      <Breadcrumbs
        nodes={breadcrumbsNodes}
        isLoading={isLoading}
      />
    </BreadcrumbsContainer>
  );
};

export default memo(OrganizationLoginPageBreadcrumbs);
