import { memo, useMemo } from 'react';
import {
  Box,
  EntityDetailsContainer,
  Grid,
  G600,
  Typography,
  useTranslations,
  formatDate,
  LoginAvatar,
  TypographyWithTooltip,
} from '@uniqkey-frontend/shared-app';
import { useGetVaultPasswordById } from '../../../../hooks/reactQuery';

interface IOrganizationLoginDetailsWidgetProps {
  vaultId: string;
}

const OrganizationLoginDetailsWidget = (props: IOrganizationLoginDetailsWidgetProps) => {
  const { vaultId } = props;
  const { t } = useTranslations();
  const {
    data: vault, isLoading, isError,
  } = useGetVaultPasswordById({ vaultId });

  const {
    name, activityAt, service, username, iconUrl,
  } = vault ?? {};
  const lastActivity = useMemo(() => formatDate({ date: activityAt }), [activityAt]);

  if (isError) {
    return null;
  }

  return (
    <EntityDetailsContainer container isLoading={isLoading}>
      <Grid container>
        <Grid item xs={6} container flexDirection="column">
          <Grid item container alignItems="center" flexWrap="nowrap">
            <LoginAvatar src={iconUrl} />
            <TypographyWithTooltip variant="h6">
              {name}
            </TypographyWithTooltip>
          </Grid>
          <Box mt={2} />
          <Grid item container>
            <TypographyWithTooltip color={G600}>
              {service}
            </TypographyWithTooltip>
          </Grid>
          <Box mt={2} />
          <Grid item container>
            <TypographyWithTooltip variant="subtitle1" color={G600}>
              {username}
            </TypographyWithTooltip>
          </Grid>
          <Box mt={4} />
        </Grid>
        <Grid item xs={6} />
      </Grid>
      <Grid container flexDirection="column" justifyContent="end" alignItems="end">
        <Grid item flexDirection="column">
          <Typography color={G600} textAlign="end">
            {t('employeeDetailsWidget.lastActivity')}
          </Typography>
        </Grid>
        <Grid item>
          <TypographyWithTooltip variant="subtitle1" color={G600} textAlign="end">
            {lastActivity}
          </TypographyWithTooltip>
        </Grid>
      </Grid>
    </EntityDetailsContainer>
  );
};

export default memo(OrganizationLoginDetailsWidget);
