import {
  useCallback, Dispatch, SetStateAction, useState,
} from 'react';
import { upperFirst } from 'lodash';
import {
  useHeroSnackbar,
  useTable,
  useQueryTableFetch,
  useTranslations,
  useTableLocalization,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  usePubSub,
  buildTablePreserverDecoderConfigItem,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  TUseTablePreserverDecoderConfigs,
  TablePreserverDecoderConfigItemTypeEnum,
  TSetSearchParams,
  IUseFilterButtonReturn,
  useFilterButton,
} from '@uniqkey-frontend/shared-app';
import {
  GetPartnerSupportRequestsRequestOrderProperty,
  GetPartnerSupportRequestsRequestStatus,
} from '@uniqkey-backend-organization-web/api-client';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import {
  IGetPartnerAccessRequestsParams,
  TGetPartnerAccessRequestsMethod,
} from '../../usePartnerSupportRequestsAPI/interfaces';
import usePartnerSupportRequestsAPI from '../../usePartnerSupportRequestsAPI';
import {
  IPartnerAccessRequestsTableRow,
} from '../../../components/tables/PartnerAccessRequestsTable';
import {
  IPartnerAccessRequestsListFilterSubmitResult,
// eslint-disable-next-line max-len
} from '../../../pages/RequestsPage/components/PartnerAccessRequestsTab/components/PartnerAccessRequestsListFilter';

export const REACT_QUERY_PARTNER_ACCESS_REQUESTS_KEY = [ReactQueryKeyEnum.PartnerAccessRequests];

export interface IUsePartnerAccessRequestsTableParams {
  noDataMessageKey: string;
}

export interface IUsePartnerAccessRequestsTableReturn extends
  Omit<IUseTableReturn<IPartnerAccessRequestsTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage' | 'resetSelectedRows'>,
  Omit<IUseQueryTableFetchReturn<TGetPartnerAccessRequestsMethod>, 'data' | 'isFetchedAfterMount'>,
  IUseFilterButtonReturn
{
  partnerAccessRequests: IUseQueryTableFetchReturn<TGetPartnerAccessRequestsMethod>['data'];
  selectedPartnerAccessRequests: IUseTableReturn<IPartnerAccessRequestsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
  filterValues: IPartnerAccessRequestsListFilterSubmitResult;
  setFilterValues: Dispatch<SetStateAction<IPartnerAccessRequestsListFilterSubmitResult>>;
}

interface ITablePreservationConfigs extends
  Omit<IGetPartnerAccessRequestsParams,
    'page' | 'pageLength' | 'orderPropertyName' | 'isDescending' | 'searchQuery'
  >,
  Pick<IUseTableReturn<IPartnerAccessRequestsTableRow>, 'columnOrderBy' | 'columnOrderDirection'>
{
  activePage: IGetPartnerAccessRequestsParams['page'];
  perPage: IGetPartnerAccessRequestsParams['pageLength'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
  columnOrderBy: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: GetPartnerSupportRequestsRequestOrderProperty },
  ),
  columnOrderDirection: true,
  statusFilter: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: GetPartnerSupportRequestsRequestStatus },
  ),
};

const usePartnerAccessRequestsTable = (
  params: IUsePartnerAccessRequestsTableParams,
): IUsePartnerAccessRequestsTableReturn => {
  const {
    noDataMessageKey,
  } = params;

  const queryClient = useQueryClient();
  const { t } = useTranslations();
  const { showError } = useHeroSnackbar();
  const { getPartnerAccessRequests } = usePartnerSupportRequestsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
    statusFilter: initialStatusFilter,
    columnOrderBy: initialColumnOrderBy,
    columnOrderDirection: initialColumnOrderDirection,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const [filterValues, setFilterValues] = useState<
    IPartnerAccessRequestsListFilterSubmitResult
  >({
    statusFilter: initialStatusFilter,
  });

  const { isFilterActive, numberOfActiveFilters } = useFilterButton(filterValues);

  const {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedRows: selectedPartnerAccessRequests,
    resetTableMethods,
    ...restTableProps
  } = useTable<IPartnerAccessRequestsTableRow>({
    initialActivePage,
    initialPerPage,
    initialColumnOrderBy,
    initialColumnOrderDirection,
  });

  const {
    resetTable,
    resetActivePage,
    resetSelectedRows,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
    setFilterValues({
      statusFilter: undefined,
    });
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: partnerAccessRequests, isLoading, isFetchedAfterMount, total, resetQuery,
  } = useQueryTableFetch({
    queryKey: REACT_QUERY_PARTNER_ACCESS_REQUESTS_KEY,
    queryClient,
    request: getPartnerAccessRequests,
    params: {
      page: activePage,
      pageLength: perPage,
      statusFilter: filterValues.statusFilter,
      orderPropertyName: GetPartnerSupportRequestsRequestOrderProperty[
        upperFirst(columnOrderBy) as keyof typeof GetPartnerSupportRequestsRequestOrderProperty
      ],
      isDescending: columnOrderDirection ? columnOrderDirection === 'desc' : undefined,
    },
    onRequestError: handleOnRequestError,
    autoRefetch: true,
  });

  const { localization } = useTableLocalization({
    isFilterActive,
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    statusFilter: filterValues.statusFilter,
  });

  return {
    partnerAccessRequests,
    isLoading,
    total,
    resetQuery,
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedPartnerAccessRequests,
    localization,
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    resetActivePage,
    resetSelectedRows,
    ...restTableProps,
  };
};

export default usePartnerAccessRequestsTable;
