import { memo, useMemo } from 'react';
import { S100 } from '@uniqkey-frontend/shared-app';
import { CircleF, type CircleProps } from '@react-google-maps/api';
import { type TGoogleMapsCoordinates } from '../../../hooks/useGoogleMapsAPI';

interface ICircleProps extends Omit<CircleProps, 'center'> {
  center: TGoogleMapsCoordinates;
}

const GoogleMapsCircle = (props: ICircleProps) => {
  const {
    center, radius, options: preOptions, ...rest
  } = props;

  const options = useMemo(() => ({
    fillColor: S100,
    fillOpacity: 0.65,
    strokeOpacity: 0,
    clickable: false,
    ...(preOptions ?? {}),
  }), [preOptions]);

  if (!center || !radius) {
    return null;
  }

  return (
    <CircleF
      center={center}
      radius={radius}
      options={options}
      {...rest}
    />
  );
};

export default memo(GoogleMapsCircle);
