import { memo } from 'react';
import {
  Grid, G600, G900, Typography,
} from '@uniqkey-frontend/shared-app';

interface IMigrationPanelHeaderProps {
  title: string;
  subtitle?: string;
}

const MigrationPanelHeader = (props: IMigrationPanelHeaderProps) => {
  const { title, subtitle } = props;
  return (
    <Grid container mt={4}>
      <Grid item container justifyContent="center" alignItems="center">
        <Typography variant="h4" align="center" color={G900}>
          {title}
        </Typography>
      </Grid>
      {subtitle && (
        <Grid item container justifyContent="center" alignItems="center" mt={2}>
          <Grid item xs={9}>
            <Typography variant="body1" align="center" color={G600}>
              {subtitle}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default memo(MigrationPanelHeader);
