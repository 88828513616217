import { useCallback, useMemo, useState } from 'react';
import type { ChartOptions } from 'chart.js';
import {
  DateRange,
  formatDateRanges,
  initDateRange,
  useHeroSnackbar,
  useTranslations,
  IDateRangePickerWithTextFieldProps,
} from '@uniqkey-frontend/shared-app';
import { GetEmployeeActivationRateItem } from '@uniqkey-backend-organization-web/api-client';
import { TLineChartType } from '../../../../../../helpers/ChartJSLib/interfaces';
import { useGetEmployeeActivationRate } from '../../../../../../hooks/reactQuery';
import DashboardEmployeeRateWidgetContainer from '../DashboardEmployeeRateWidgetContainer';

const DEFAULT_VALUES = { refreshDate: '', data: [] };

const DashboardEmployeeActivationRateWidget = () => {
  const { t } = useTranslations();
  const { showError } = useHeroSnackbar();

  const [range, setRange] = useState<DateRange>(() => initDateRange({
    to: new Date(),
    duration: { day: 7 },
  }));
  const formattedRange = useMemo(
    () => formatDateRanges(range, { asISODate: true, inUTC: false }),
    [range],
  );
  const handleRangeChange = useCallback<
    IDateRangePickerWithTextFieldProps['onSubmit']
  >((newRange) => {
    setRange(newRange);
  }, []);

  const { data: employeeActivationRate, isLoading, isError } = useGetEmployeeActivationRate(
    { from: formattedRange.from!, to: formattedRange.to! },
    {
      onError: () => {
        showError({ text: t('common.somethingWentWrong') });
      },
      enabled: !!(formattedRange.from && formattedRange.to),
    },
  );

  const options = useMemo<ChartOptions<TLineChartType>>(() => ({
    plugins: {
      tooltip: {
        callbacks: {
          title: ([tooltipItem]) => {
            const { raw, formattedValue } = tooltipItem;
            const {
              activatedEmployees,
              totalEmployees,
            } = raw as GetEmployeeActivationRateItem;
            return [
              t('employeeActivationRateWidget.chart.tooltip.rate', { rate: formattedValue }),
              t('employeeActivationRateWidget.chart.tooltip.activated', {
                activatedEmployees, totalEmployees,
              }),
            ];
          },
        },
        titleAlign: 'left',
        bodyAlign: 'left',
      },
    },
  }), [t]);

  if (isError) {
    return null;
  }

  const { refreshDate, data } = employeeActivationRate ?? DEFAULT_VALUES;

  return (
    <DashboardEmployeeRateWidgetContainer
      headerTranslationKey="employeeActivationRateWidget.title"
      tooltipTranslationKey="employeeActivationRateWidget.tooltip"
      updatedAtTranslationKey="employeeActivationRateWidget.updatedAt"
      noDataTranslationKey="employeeActivationRateWidget.noData"
      updatedAt={refreshDate}
      from={range.from}
      to={range.to}
      onSubmit={handleRangeChange}
      onClear={handleRangeChange}
      isLoading={isLoading}
      data={data}
      options={options}
    />
  );
};

export default DashboardEmployeeActivationRateWidget;
