import {
  useCallback, useEffect, useMemo, useState, ReactNode,
} from 'react';
import { useQueryClient } from 'react-query';
import { useHeroSnackbar, useTranslations, usePubSub } from '@uniqkey-frontend/shared-app';
import PubSubEventEnum from '../../enums/PubSubEventEnum';
import UserContext from '.';
import { getJWTTokens, type ILogoutParams } from '../../services/authService';

interface IUserContextProps {
  children: ReactNode,
}

const UserProviderContext = (props: IUserContextProps) => {
  const { children } = props;
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => !!getJWTTokens());
  const { t } = useTranslations();
  const { showError } = useHeroSnackbar();
  const queryClient = useQueryClient();

  const handleLogin = useCallback(() => {
    setIsAuthenticated(true);
  }, []);

  const handleLogout = useCallback((message: string, data: Partial<ILogoutParams>) => {
    setIsAuthenticated((prevIsAuthenticated) => {
      if (!prevIsAuthenticated) {
        return false;
      }
      const { showMessage } = data ?? {};
      if (showMessage) {
        showError({ text: t('common.unauthorizedError') });
      }
      queryClient.clear();
      return false;
    });
  }, [queryClient, showError, t]);

  usePubSub(PubSubEventEnum.LOGIN, handleLogin);

  usePubSub(PubSubEventEnum.LOGOUT, handleLogout);

  useEffect(() => {
    const handler = (event: StorageEvent) => {
      if (event.storageArea === sessionStorage) {
        setIsAuthenticated(!!event.newValue);
      }
    };
    window.addEventListener('storage', handler);
    return () => {
      window.removeEventListener('storage', handler);
    };
  }, []);

  const value = useMemo(() => ({
    isAuthenticated,
  }), [
    isAuthenticated,
  ]);

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProviderContext;
