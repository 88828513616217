import { memo } from 'react';
import {
  WidgetContainer,
  Grid,
  TypographyWithHelp,
  G900,
  Typography,
  useTranslations,
} from '@uniqkey-frontend/shared-app';

interface IDashboardNoDataWidgetProps {
  tooltipTranslationKey: string;
  titleTranslationKey: string;
  noDataTranslationKey: string;
}

const DashboardNoDataWidget = (props: IDashboardNoDataWidgetProps) => {
  const { tooltipTranslationKey, titleTranslationKey, noDataTranslationKey } = props;
  const { t } = useTranslations();

  return (
    <WidgetContainer container withShadow minHeight={270}>
      <Grid container alignContent="space-between" justifyContent="center">
        <Grid item>
          <TypographyWithHelp
            helperText={t(tooltipTranslationKey)}
            color={G900}
          >
            {t(titleTranslationKey)}
          </TypographyWithHelp>
        </Grid>
        <Grid item container justifyContent="center">
          <Typography>
            {t(noDataTranslationKey)}
          </Typography>
        </Grid>
        <Grid item container />
      </Grid>
    </WidgetContainer>
  );
};

export default memo(DashboardNoDataWidget);
