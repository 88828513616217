import {
  useCallback, useState, memo, useMemo, ChangeEvent,
} from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  useTranslations,
  Box,
  validateEmail,
  validateNoSpaces,
  generateGUID,
  useHeroSnackbar,
  DEFAULT_LANGUAGE,
  ISearchableTextFieldProps,
  TSelectProps,
} from '@uniqkey-frontend/shared-app';
import { EmployeeAccountStatus } from '@uniqkey-backend-organization-web/api-client';
import InviteEmployeeForm from './components/InviteEmployeeForm';
import InviteEmployeesDialogActions from './components/InviteEmployeesDialogActions';
import InviteEmployeesForm from './components/InviteEmployeesForm';
import InviteEmployeesTable from './components/InviteEmployeesTable';
import { IInviteEmployee, IInviteEmployeesParams, IReadEmployeesResult } from './interfaces';

interface IInviteEmployeesModalProps {
  isOpen: boolean;
  onClose: () => void;
  onEmployeesInvite: (params: IInviteEmployeesParams) => Promise<void>;
  isLoading: boolean;
}

const InviteEmployeesModal = (props: IInviteEmployeesModalProps) => {
  const {
    isOpen, onClose, onEmployeesInvite, isLoading,
  } = props;
  const [language, setLanguage] = useState(DEFAULT_LANGUAGE);
  const [shouldInvite, setShouldInvite] = useState<EmployeeAccountStatus>();
  const [usersToEmailsMap, setUsersToEmailsMap] = useState<Map<string, IInviteEmployee>>(new Map());
  const [emailsToIdsMap, setEmailsToIdsMap] = useState<Map<string, string>>(new Map());
  const [usersToFilenamesMap, setUsersToFilenamesMap] = useState<Map<string, string[]>>(new Map());
  const [errorsCountToFilenamesMap, setErrorsCountToFilenamesMap] = useState<Map<string, number>>(
    new Map(),
  );
  const [searchQuery, setSearchQuery] = useState<string>('');
  const { t } = useTranslations();
  const { showSuccess, showError } = useHeroSnackbar();

  const handleLanguageChange = useCallback<
    NonNullable<TSelectProps['onChange']>
  >((event) => setLanguage(event.target.value as string), []);

  const handleSingleEmployeeSubmit = useCallback((newUser: IInviteEmployee): void => {
    const newUsersToEmailsMap: Map<string, IInviteEmployee> = new Map(
      [[newUser.email, newUser], ...Array.from(usersToEmailsMap)],
    );
    setUsersToEmailsMap(newUsersToEmailsMap);
    setEmailsToIdsMap(new Map(emailsToIdsMap.set(newUser.id, newUser.email)));
  }, [usersToEmailsMap, emailsToIdsMap]);

  const handleMultipleEmployeesSubmit = useCallback((readResults: IReadEmployeesResult[]): void => {
    let numberOfInvalidEmails = 0;
    const updatedUsersMap = new Map(usersToEmailsMap);
    const updatedFilenamesMap = new Map(usersToFilenamesMap);
    const updatedErrorsCountFilenamesMap = new Map(errorsCountToFilenamesMap);
    const updatedIdsMap = new Map(emailsToIdsMap);
    readResults.forEach((readResult) => {
      if (updatedFilenamesMap.has(readResult.fileName)) {
        showError({ text: t('inviteEmployeesModal.fileExists') });
        return;
      }
      const usersToAdd: string[] = [];
      readResult.users.forEach((user) => {
        const email = user.email.trim();
        if (!validateEmail(email) || !validateNoSpaces(email)) {
          numberOfInvalidEmails += 1;
          return;
        }
        const newUser = {
          email,
          name: user.name?.trim(),
          id: generateGUID(),
        };
        updatedUsersMap.set(newUser.email, newUser);
        updatedIdsMap.set(newUser.id, newUser.email);
        usersToAdd.push(newUser.id);
      });
      updatedFilenamesMap.set(readResult.fileName, usersToAdd);
      if (numberOfInvalidEmails) {
        updatedErrorsCountFilenamesMap.set(readResult.fileName, numberOfInvalidEmails);
      }
    });
    setUsersToEmailsMap(updatedUsersMap);
    setEmailsToIdsMap(updatedIdsMap);
    setUsersToFilenamesMap(updatedFilenamesMap);
    setErrorsCountToFilenamesMap(updatedErrorsCountFilenamesMap);
  }, [
    usersToEmailsMap,
    usersToFilenamesMap,
    emailsToIdsMap,
    errorsCountToFilenamesMap,
    showError,
    t,
  ]);

  const handleRadioGroupChange = useCallback((
    event: ChangeEvent<HTMLInputElement>,
  ) => setShouldInvite(event.target.value as EmployeeAccountStatus), []);
  const handleSearchChange = useCallback<ISearchableTextFieldProps['onChange']>(
    (debouncedValue) => setSearchQuery(debouncedValue || ''),
    [],
  );
  const handleUserDelete = useCallback((usersIds: string[]) => {
    const updatedUsersMap = new Map(usersToEmailsMap);
    const updatedEmailsMap = new Map(emailsToIdsMap);
    usersIds.forEach((id) => {
      const userEmail = emailsToIdsMap.get(id);
      updatedEmailsMap.delete(id);
      updatedUsersMap.delete(userEmail!);
    });
    setUsersToEmailsMap(updatedUsersMap);
    setEmailsToIdsMap(updatedEmailsMap);
    showSuccess({ text: t('common.removed') });
  }, [usersToEmailsMap, emailsToIdsMap, showSuccess, t]);

  const handleFileDelete = useCallback((fileName: string) => {
    const usersToDelete = usersToFilenamesMap.get(fileName);
    handleUserDelete(usersToDelete!);
    const updatedFilenamesMap = new Map(usersToFilenamesMap);
    updatedFilenamesMap.delete(fileName);
    setUsersToFilenamesMap(updatedFilenamesMap);
    const updatedErrorsCountFilenamesMap = new Map(errorsCountToFilenamesMap);
    updatedErrorsCountFilenamesMap.delete(fileName);
    setErrorsCountToFilenamesMap(updatedErrorsCountFilenamesMap);
  }, [handleUserDelete, usersToFilenamesMap, errorsCountToFilenamesMap]);

  const usersForTable = useMemo(() => Array.from(usersToEmailsMap.values()).filter(
    (user) => (user.email.includes(searchQuery) || user.name?.includes(searchQuery)),
  ), [usersToEmailsMap, searchQuery]);
  const fileNamesForTable = useMemo(
    () => Array.from(usersToFilenamesMap.keys()),
    [usersToFilenamesMap],
  );
  const isSubmitDisabled = useMemo(
    () => !usersForTable.length || !shouldInvite,
    [usersForTable, shouldInvite],
  );
  const invalidEmailsCount = useMemo(() => Array.from(
    errorsCountToFilenamesMap.values(),
  ).reduce((acc, errorsCount) => acc + errorsCount, 0), [errorsCountToFilenamesMap]);

  const handleSubmitForm = useCallback(() => {
    const users = Array.from(usersToEmailsMap.values());
    onEmployeesInvite({
      shouldInvite: shouldInvite === EmployeeAccountStatus.Invited,
      users,
      language,
    });
  }, [onEmployeesInvite, shouldInvite, language, usersToEmailsMap]);

  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      fullWidth
      maxWidth="md"
      clickOutsideToClose={!isLoading}
    >
      <DialogTitle isLoading={isLoading} onClose={onClose}>
        {t('inviteEmployeesModal.title')}
      </DialogTitle>
      <DialogContent>
        <InviteEmployeeForm onSubmit={handleSingleEmployeeSubmit} />
        <Box mt={3} />
        <InviteEmployeesForm onSubmit={handleMultipleEmployeesSubmit} />
        <Box mt={2} />
        <InviteEmployeesTable
          onSearchChange={handleSearchChange}
          users={usersForTable}
          onUserDelete={handleUserDelete}
          fileNames={fileNamesForTable}
          invalidEmailsCount={invalidEmailsCount}
          onFileDelete={handleFileDelete}
        />
      </DialogContent>
      <InviteEmployeesDialogActions
        isDisabled={isSubmitDisabled}
        onLanguageChange={handleLanguageChange}
        language={language}
        onSubmit={handleSubmitForm}
        isLoading={isLoading}
        onRadioGroupChange={handleRadioGroupChange}
      />
    </Dialog>
  );
};

export default memo(InviteEmployeesModal);
