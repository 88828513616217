import {
  ScrollablePanelContent,
  Grid,
  useHeroSnackbar,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import { GetRetentionPeriodResponse } from '@uniqkey-backend-organization-web/api-client';
import RetentionPeriodWidget from './components/RetentionPeriodWidget';
import {
  useGetOrganizationRetentionPeriod,
} from '../../../../hooks/reactQuery';
import SoftDeletionPeriodWidget from './components/SoftDeletionPeriodWidget';
import GeneralRetentionPeriodWidget from './components/GeneralRetentionPeriodWidget';
import RestoreDataWidget from './components/RestoreDataWidget';

const RetentionPeriodTab = () => {
  const { t } = useTranslations();
  const { showError } = useHeroSnackbar();

  const {
    data: retentionPeriodData,
    isLoading: isRetentionPeriodDataLoading,
    isError: isRetentionPeriodDataError,
  } = useGetOrganizationRetentionPeriod({
    onError: () => {
      showError({ text: t('common.somethingWentWrong') });
    },
  });

  const {
    softDeletionPeriodInDaysForArchivedEmployees,
    softDeletionPeriodInDaysForUnmanagedVaults,
    softDeletionPeriodInDaysForAuditLogs,
    retentionPeriodInDays,
  } = retentionPeriodData ?? {} as GetRetentionPeriodResponse;

  return (
    <ScrollablePanelContent p={3}>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid container item xs={6} rowGap={3}>
          <RetentionPeriodWidget />
          <SoftDeletionPeriodWidget
            data={{
              softDeletionPeriodInDaysForArchivedEmployees,
              softDeletionPeriodInDaysForUnmanagedVaults,
              softDeletionPeriodInDaysForAuditLogs,
            }}
            isLoading={isRetentionPeriodDataLoading}
            isError={isRetentionPeriodDataError}
          />
        </Grid>
        <Grid container item xs={6} rowGap={3}>
          <GeneralRetentionPeriodWidget
            retentionPeriodInDays={retentionPeriodInDays}
            isLoading={isRetentionPeriodDataLoading}
            isError={isRetentionPeriodDataError}
          />
          <RestoreDataWidget
            retentionPeriodInDays={retentionPeriodInDays}
            isRetentionPeriodDataLoading={isRetentionPeriodDataLoading}
          />
        </Grid>
      </Grid>
    </ScrollablePanelContent>
  );
};

export default RetentionPeriodTab;
